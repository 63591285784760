<template>
  <div>
    <div @click="hotfix">
      {{ $t("handle.hotfix") }}
    </div>

    <el-dialog
      :title="$t('handle.hotfix')"
      :visible.sync="hotfixVisible"
      top="30px"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="1000px"
    >
      <el-tabs v-model="hotfixTab" type="card" v-if="hotfixVisible">
        <el-tab-pane :label="$t('basicConf')" name="basic">
          <basic-conf update :form="info" :vendor="vendor" :region="region" :cluster="cluster" ref="basicConf" />
        </el-tab-pane>

        <el-tab-pane
          v-for="item in getContainer()"
          :key="item.name"
          :label="`${$t('container')}(${item.name})`"
          :name="item.name"
        >
          <container-conf
            :containerForm="item"
            :ref="`container-${item.name}`"
            :namespace="info.metadata.namespace"
            :locationList="locationList"
          >
            <template #confMap>
              <config-map
                :namespace="info.metadata.namespace"
                :configs="findConfigConf(item.id, 'configMap')"
                :disabledNames="[]"
                :ref="`config-map-${item.name}`"
                :locationList="locationList"
                type="configMap"
              />
            </template>

            <template #secret>
              <config-map
                :namespace="info.metadata.namespace"
                :configs="findConfigConf(item.id, 'secret')"
                :disabledNames="[]"
                :ref="`secret-${item.name}`"
                :locationList="locationList"
                type="secret"
              />
            </template>

            <template #volume>
              <volume-conf :volumes="findVolumeConf(item.id)" :ref="`volume-${item.name}`" />
            </template>

            <template #pvc>
              <pvc-conf
                :namespace="info.metadata.namespace"
                :pvc="findPvcConf(item.id)"
                :disabledNames="existedPersistentVolumeClaimsNames"
                :ref="`pvc-${item.name}`"
                :locationList="locationList"
              />
            </template>
          </container-conf>
        </el-tab-pane>

        <el-tab-pane
          :label="`${$t('Service')}(${item.metadata.name})`"
          :name="`tab-svc-${item.metadata.name}`"
          v-for="item in info.spec.services"
          :key="`tab-svc-${item.metadata.name}`"
        >
          <service-conf
            :serviceForm="item"
            :ref="`service-${item.metadata.name}`"
            :namespace="info.metadata.namespace"
            :locationList="locationList"
            update
          />
        </el-tab-pane>
      </el-tabs>

      <span slot="footer">
        <el-button type="primary" @click="toHotfix" size="small">
          {{ $t("handle.toYAML") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('handle.hotfix')"
      :visible.sync="hotfixDialogVisible"
      :close-on-click-modal="false"
      @opened="diffInit"
      @closed="hotfixClosed"
      :destroy-on-close="true"
      :append-to-body="true"
      width="96%"
      top="30px"
    >
      <div class="yaml-diff-header">
        <div class="diff-header">Before</div>
        <div class="diff-header">After</div>
      </div>
      <div id="acediff"></div>

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="hotfixLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const uuidv4 = require("uuid/v4");
const YAML = require("json2yaml");
import { cloneDeep, includes, startsWith } from "lodash";

import { applicationUpdate } from "api/deployment";

import AceDiff from "ace-diff";
import "ace-diff/dist/ace-diff-dark.min.css";
require("brace/mode/yaml");
require("brace/theme/monokai");

import BasicConf from "./Add/BasicConf";
import ContainerConf from "./Add/ContainerConf";
import ConfigMap from "./Add/ConfigMap";
import PvcConf from "./Add/PvcConf";
import VolumeConf from "./Add/VolumeConf";
import ServiceConf from "./Add/ServiceConf";

import { resourceHandler, initResource, affinityHandler, imagePullSecretsHandler } from "./Add/utils";

export default {
  components: {
    BasicConf,
    ContainerConf,
    ConfigMap,
    PvcConf,
    ServiceConf,
    VolumeConf
  },
  data() {
    return {
      hotfixVisible: false,
      hotfixLoading: false,
      hotfixDialogVisible: false,
      info: {},
      hotfixTab: "basic",
      differ: null,
      yamlOriginText: "",
      yamlText: ""
    };
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    locationList() {
      return [this.$route.query.vendor, this.$route.query.region, this.$route.query.cluster];
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    // existedConfigMapNames() {
    //   let names = [];
    //   if (this.info.spec.configMaps)
    //     this.info.spec.configMaps.forEach(item => {
    //       item.configs.forEach(config => {
    //         names.push(config.name);
    //       });
    //     });
    //   return names;
    // },

    existedPersistentVolumeClaimsNames() {
      let names = [];
      if (this.info.spec.persistentVolumeClaims)
        this.info.spec.persistentVolumeClaims.forEach(item => {
          item.persistentVolumeClaims.forEach(pvc => {
            names.push(pvc.claimName);
          });
        });
      return names;
    }
  },

  mounted() {
    this.info = cloneDeep(this.$attrs.data);
  },

  methods: {
    hotfix() {
      this.hotfixVisible = true;
      this.yamlOriginText = this.generateYAML(this.info);

      if (!this.info.spec.configMaps) this.info.spec.configMaps = [];
      if (!this.info.spec.secretMaps) this.info.spec.secretMaps = [];
      if (!this.info.spec.persistentVolumeClaims) this.info.spec.persistentVolumeClaims = [];
      if (!this.info.spec.volumes) this.info.spec.volumes = [];

      let currentContainer = this.getContainer();

      currentContainer.forEach(item => {
        let uuid = uuidv4();
        // 热修改反匹配
        item.id = uuid;

        initResource(item.resources);

        if (!item.ports) this.$set(item, "ports", []);

        if (this.info.spec.configMaps)
          this.info.spec.configMaps.forEach(config => {
            if (config.container == item.name) config.id = uuid;
          });

        if (this.info.spec.secretMaps)
          this.info.spec.secretMaps.forEach(config => {
            if (config.container == item.name) config.id = uuid;
          });

        if (this.info.spec.persistentVolumeClaims)
          this.info.spec.persistentVolumeClaims.forEach(pvc => {
            if (pvc.container == item.name) pvc.id = uuid;
          });

        if (this.info.spec.volumes)
          this.info.spec.volumes.forEach(volume => {
            if (volume.container == item.name) volume.id = uuid;
          });
      });
    },

    getContainer() {
      if (this.type == "Deployment") {
        return this.info.spec.deployment.spec.template.spec.containers;
      }
      if (this.type == "CronJob") {
        return this.info.spec.cronjob.spec.jobTemplate.spec.template.spec.containers;
      }
      if (this.type == "DaemonSet") {
        return this.info.spec.daemonset.spec.template.spec.containers;
      }
      if (this.type == "StatefulSet") {
        return this.info.spec.statefulset.spec.template.spec.containers;
      }
    },

    findConfigConf(id, type) {
      let arr = null;
      type === "configMap" ? (arr = this.info.spec.configMaps) : (arr = this.info.spec.secretMaps);
      if (arr) {
        for (let item of arr) {
          if (item.id == id) return item.configs;
        }
      } else {
        return [];
      }
    },

    findPvcConf(id) {
      if (this.info.spec.persistentVolumeClaims) {
        for (let item of this.info.spec.persistentVolumeClaims) {
          if (item.id == id) return item.persistentVolumeClaims;
        }
      } else {
        return [];
      }
    },

    findVolumeConf(id) {
      if (this.info.spec.volumes) {
        for (let item of this.info.spec.volumes) {
          if (item.id == id) return item.volumes;
        }
      } else {
        return [];
      }
    },

    diffInit() {
      this.differ = new AceDiff({
        element: "#acediff",
        mode: "ace/mode/yaml",
        theme: "ace/theme/monokai",
        left: {
          content: this.yamlOriginText
        },
        right: {
          content: this.yamlText
        }
      });
    },

    generateYAML(json) {
      let cloneJson = cloneDeep(json);

      if (cloneJson.spec.secretMaps && cloneJson.spec.secretMaps.length > 0) {
        cloneJson.spec.secretMaps.forEach(item => delete item.id);
      }

      if (cloneJson.spec.secretMaps && cloneJson.spec.secretMaps.length == 0) {
        delete cloneJson.spec.secretMaps;
      }

      if (cloneJson.spec.configMaps && cloneJson.spec.configMaps.length > 0) {
        cloneJson.spec.configMaps.forEach(item => delete item.id);
      }

      if (cloneJson.spec.configMaps && cloneJson.spec.configMaps.length == 0) {
        delete cloneJson.spec.configMaps;
      }

      if (cloneJson.spec.persistentVolumeClaims && cloneJson.spec.persistentVolumeClaims.length > 0) {
        cloneJson.spec.persistentVolumeClaims.forEach(item => delete item.id);
      }

      if (cloneJson.spec.persistentVolumeClaims && cloneJson.spec.persistentVolumeClaims.length == 0) {
        delete cloneJson.spec.persistentVolumeClaims;
      }

      if (cloneJson.spec.volumes && cloneJson.spec.volumes.length > 0) {
        cloneJson.spec.volumes.forEach(item => delete item.id);
      }

      if (cloneJson.spec.volumes && cloneJson.spec.volumes.length == 0) {
        delete cloneJson.spec.volumes;
      }

      if (this.type == "Deployment" || this.type == "DaemonSet" || this.type == "StatefulSet") {
        cloneJson.spec[this.type.toLowerCase()].spec.template.spec.containers.forEach(item => {
          delete item.id;
          if (item.env && item.env.length == 0) delete item.env;
        });
      }

      if (this.type == "CronJob") {
        cloneJson.spec.cronjob.spec.jobTemplate.spec.template.spec.containers.forEach(item => {
          delete item.id;
          if (item.env && item.env.length == 0) delete item.env;
        });
      }

      cloneJson.spec[this.type.toLowerCase()] = {
        spec: cloneJson.spec[this.type.toLowerCase()].spec
      };

      if (cloneJson.spec.services && cloneJson.spec.services.length == 0) delete cloneJson.spec.services;

      if (cloneJson.spec.services && cloneJson.spec.services.length > 0) {
        cloneJson.spec.services.forEach(item => {
          item.metadata = { name: item.metadata.name, annotations: item.metadata.annotations };
          delete item.status;
        });
      }

      return YAML.stringify({ spec: cloneJson.spec });
    },

    toHotfix() {
      let validLength = 0;
      let serviceValidLength = 0;
      let currentContainer = this.getContainer();

      currentContainer.forEach(async item => {
        let name = item.name;

        // let containerValid = await this.$refs[`container-${name}`][0].$refs[`form`].validate();

        // let configMapValid = await this.$refs[`config-map-${name}`][0].$refs[`form`].validate();
        // if (!configMapValid) this.$refs[`container-${name}`][0].collapseVal = ["2"];

        // let secretValid = await this.$refs[`secret-${name}`][0].$refs[`form`].validate();
        // if (!secretValid) this.$refs[`container-${name}`][0].collapseVal = ["secret"];

        // let pvcValid = await this.$refs[`pvc-${name}`][0].$refs[`form`].validate();
        // if (!pvcValid) this.$refs[`container-${name}`][0].collapseVal = ["3"];

        // let volumeValid = await this.$refs[`volume-${name}`][0].$refs[`form`].validate();
        // if (!pvcValid) this.$refs[`container-${name}`][0].collapseVal = ["volume"];

        this.$refs[`container-${name}`][0].$refs[`form`].validate(containerValid => {
          if (containerValid) {
            this.$refs[`config-map-${name}`][0].$refs[`form`].validate(configMapValid => {
              if (configMapValid) {
                this.$refs[`secret-${name}`][0].$refs[`form`].validate(secretValid => {
                  if (secretValid) {
                    this.$refs[`pvc-${name}`][0].$refs[`form`].validate(pvcValid => {
                      if (pvcValid) {
                        this.$refs[`volume-${name}`][0].$refs[`form`].validate(volumeValid => {
                          if (volumeValid) {
                            validLength++;
                          } else {
                            this.hotfixTab = name;
                            this.$refs[`container-${name}`][0].collapseVal = ["volume"];
                          }
                        });
                      } else {
                        this.hotfixTab = name;
                        this.$refs[`container-${name}`][0].collapseVal = ["3"];
                      }
                    });
                  } else {
                    this.hotfixTab = name;
                    this.$refs[`container-${name}`][0].collapseVal = ["secret"];
                  }
                });
              } else {
                this.hotfixTab = name;
                this.$refs[`container-${name}`][0].collapseVal = ["2"];
              }
            });
          } else {
            this.hotfixTab = name;
          }
        });
      });

      if (this.info.spec.services) {
        this.info.spec.services.forEach(item => {
          let name = item.metadata.name;
          this.$refs[`service-${name}`][0].$refs[`form`].validate(valid => {
            if (valid) {
              serviceValidLength++;
            } else {
              this.hotfixTab = `tab-svc-${name}`;
            }
          });
        });
      } else {
        this.info.spec.services = [];
      }

      if (validLength == currentContainer.length && serviceValidLength == this.info.spec.services.length) {
        currentContainer.forEach((item, index, arr) => {
          let name = item.name;

          let containerData = this.$refs[`container-${name}`][0].form;
          let configsTemp = cloneDeep(this.$refs[`config-map-${name}`][0].form.configs);
          let existConfigMaps = this.info.spec.configMaps.map(item => item.id);

          resourceHandler(this.$refs[`container-${name}`][0].form.resources);

          if (includes(existConfigMaps, item.id)) {
            // 替换 删除
            this.info.spec.configMaps.forEach((config, index, arr) => {
              if (config.id == item.id) {
                if (configsTemp.length > 0) {
                  arr.splice(index, 1, {
                    container: containerData.name,
                    configs: [...configsTemp],
                    id: containerData.id
                  });
                } else {
                  arr.splice(index, 1);
                }
              }
            });
          } else {
            //添加
            if (configsTemp.length > 0)
              this.info.spec.configMaps.push({
                container: containerData.name,
                configs: [...configsTemp],
                id: containerData.id
              });
          }

          let secretTemp = cloneDeep(this.$refs[`secret-${name}`][0].form.configs);
          let existSecretMaps = this.info.spec.secretMaps.map(item => item.id);

          if (includes(existSecretMaps, item.id)) {
            // 替换 删除
            this.info.spec.secretMaps.forEach((config, index, arr) => {
              if (config.id == item.id) {
                if (secretTemp.length > 0) {
                  arr.splice(index, 1, {
                    container: containerData.name,
                    configs: [...secretTemp],
                    id: containerData.id
                  });
                } else {
                  arr.splice(index, 1);
                }
              }
            });
          } else {
            //添加
            if (secretTemp.length > 0)
              this.info.spec.secretMaps.push({
                container: containerData.name,
                configs: [...secretTemp],
                id: containerData.id
              });
          }

          let pvcTemp = cloneDeep(this.$refs[`pvc-${name}`][0].form.persistentVolumeClaims);
          let existPvc = this.info.spec.persistentVolumeClaims.map(item => item.id);

          if (includes(existPvc, item.id)) {
            // 替换 删除
            this.info.spec.persistentVolumeClaims.forEach((pvc, index, arr) => {
              if (pvc.id == item.id) {
                if (pvcTemp.length > 0) {
                  arr.splice(index, 1, {
                    container: containerData.name,
                    persistentVolumeClaims: [...pvcTemp],
                    id: containerData.id
                  });
                } else {
                  arr.splice(index, 1);
                }
              }
            });
          } else {
            //添加
            if (pvcTemp.length > 0)
              this.info.spec.persistentVolumeClaims.push({
                container: containerData.name,
                persistentVolumeClaims: [...pvcTemp],
                id: containerData.id
              });
          }

          let volumeTemp = cloneDeep(this.$refs[`volume-${name}`][0].form.volumes);
          let existVolumes = this.info.spec.volumes.map(item => item.id);

          if (includes(existVolumes, item.id)) {
            // 替换 删除
            this.info.spec.volumes.forEach((volume, index, arr) => {
              if (volume.id == item.id) {
                if (volumeTemp.length > 0) {
                  arr.splice(index, 1, {
                    container: containerData.name,
                    volumes: [...volumeTemp],
                    id: containerData.id
                  });
                } else {
                  arr.splice(index, 1);
                }
              }
            });
          } else {
            //添加
            if (volumeTemp.length > 0)
              this.info.spec.volumes.push({
                container: containerData.name,
                volumes: [...volumeTemp],
                id: containerData.id
              });
          }

          if (this.$refs[`container-${name}`][0].visible.startCommand) {
            if (this.$refs[`container-${name}`][0].form.command.length < 1) {
              delete containerData.command;
            }

            if (this.$refs[`container-${name}`][0].form.args.length < 1) {
              delete containerData.args;
            }
          }

          if (item.id == containerData.id) arr.splice(index, 1, containerData);
        });

        if (this.info.spec.services) {
          this.info.spec.services.forEach((item, index, arr) => {
            let name = item.metadata.name;
            let serviceData = this.$refs[`service-${name}`][0].form;
            if (
              this.$refs[`service-${name}`][0].form.spec.type == "NodePort" &&
              this.$refs[`service-${name}`][0].form.metadata.annotations &&
              this.$refs[`service-${name}`][0].form.metadata.annotations["ingress-access-type"] == "subpath"
            ) {
              if (!startsWith(this.$refs[`service-${name}`][0].form.metadata.annotations["ingress-subpath"], "/")) {
                this.$refs[`service-${name}`][0].form.metadata.annotations["ingress-subpath"] =
                  "/" + this.$refs[`service-${name}`][0].form.metadata.annotations["ingress-subpath"];
              }
            }

            if (name == serviceData.metadata.name) arr.splice(index, 1, serviceData);
          });
        }

        this.hotfixDialogVisible = true;

        if (
          Array.isArray(this.$refs["basicConf"].imagePullSecrets) &&
          this.$refs["basicConf"].imagePullSecrets.length > 0
        ) {
          let data = this.$refs["basicConf"].imagePullSecrets.map(item => {
            return { name: item };
          });

          imagePullSecretsHandler(this.info, data);
        }

        affinityHandler(this.info);

        this.yamlText = this.generateYAML(this.info);
      }
    },

    hotfixClosed() {
      //this.differ.destroy();
      // 更改一个容器名字后，组件重新挂载，但未触发created()深拷贝
      // 调用方法重新销毁组件后执行深拷贝
      this.$emit("update");
    },

    submit() {
      this.hotfixLoading = true;

      applicationUpdate(this.info, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
        response => {
          if (response.code === 0) {
            this.$notify({
              title: "Success",
              message: "Success",
              type: "success"
            });

            this.hotfixLoading = false;

            this.hotfixDialogVisible = false;
            this.hotfixVisible = false;

            this.$emit("update");
          }
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
#acediff {
  width: 100%;
  height: 70vh;
  position: relative;

  .ace_scrollbar-v,
  .ace_scrollbar-h {
    display: none;
  }
}

.yaml-diff-header {
  margin-bottom: 10px;

  & > div {
    display: inline-block;
    width: 50%;
    text-align: center;
    font-size: 20px;
    color: #242e42;
    text-shadow: 0 4px 8px rgba(36, 46, 66, 0.1);
  }
}
</style>
