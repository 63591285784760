<template>
  <div class="breadcrumb-container">
    <svg-icon icon-class="back" class-name="back" @click.native="goBack"></svg-icon>

    <el-breadcrumb separator="/" class="app-breadcrumb" style="font-size: 12px;">
      <el-breadcrumb-item :to="{ path: '/' }">
        {{ $t("KubeStar") }}
      </el-breadcrumb-item>

      <el-breadcrumb-item v-if="type == 'Pod' || type == 'Node'">
        {{ type }}
      </el-breadcrumb-item>

      <el-breadcrumb-item :to="{ path: `/list/${type}` }" v-else>
        {{ type }}
      </el-breadcrumb-item>

      <el-breadcrumb-item>
        {{ name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  computed: {
    name() {
      return this.$route.params.name;
    },

    type() {
      return this.$route.params.type;
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
.breadcrumb-container {
  margin-bottom: 8px;

  .svg-icon.back {
    width: 16px;
    height: 16px;
    margin-right: $grid-height;
    cursor: pointer;
  }
}

.app-breadcrumb.el-breadcrumb {
  display: inline-block;

  .el-breadcrumb__inner {
    color: $color-main;

    &.is-link {
      color: $color-main;
      font-weight: normal;

      &:hover {
        color: $color-primary;
      }
    }
  }
}
</style>
