<template>
  <div>
    <div class="search-normal-container clearfix">
      <div class="list-title pull-left">
        {{ $t(type) }}
      </div>

      <div class="search-input-wrapper">
        <el-input
          prefix-icon="el-icon-search"
          v-model="searchText"
          size="mini"
          @keyup.enter.native="getList(1)"
          class="search-input"
        >
        </el-input>

        <el-tooltip effect="dark" :content="$t('handle.refresh')" placement="bottom-start">
          <el-button plain icon="el-icon-refresh" size="mini" @click="getList(currentPage)"></el-button>
        </el-tooltip>
      </div>
    </div>

    <div v-loading="loading">
      <el-table :data="list">
        <el-table-column :label="$t('ConfigMap')" prop="metadata.name" sortable min-width="150">
          <template slot-scope="scope">
            <div class="table-name-info">
              <svg-icon icon-class="ConfigMap" v-if="type == 'ConfigMap'"></svg-icon>
              <svg-icon icon-class="Secret" v-else></svg-icon>

              <div class="content">
                <a @click.prevent="toDetail(scope.row.metadata.name)">
                  {{ scope.row.metadata.name }}
                </a>
                <span class="desc">
                  {{ moment(scope.row.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss") }}
                </span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('configMapItem')">
          <template slot-scope="scope">
            {{ scope.row.data ? Object.keys(scope.row.data).join(",") : "-" }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      layout="prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { configMapListByLocation } from "api/configmap";
import { secretListByLocation } from "api/secret";
import moment from "moment";

export default {
  props: {
    type: String
  },
  data() {
    return {
      list: [],
      total: 0,
      loading: true,
      currentPage: 1,
      searchText: "",
      currentPageSize: 10
    };
  },

  methods: {
    moment,

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    },

    getList(page = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = page;

      let action = this.type == "ConfigMap" ? configMapListByLocation : secretListByLocation;

      action(
        {
          ...this.$route.query,
          namespace: this.$route.params.name
        },
        {
          page,
          pageSize,
          search: [this.searchText]
        }
      ).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.list = response.data.items;
          this.total = response.data.total;
        }
      });
    },

    toDetail(name) {
      let path = this.type == "ConfigMap" ? `/detail/ConfigMap/${name}` : `/detail/Secret/${name}`;

      this.$router.push({
        path,
        query: { ...this.$route.query, namespace: this.$route.params.name }
      });
    }
  },

  mounted() {
    this.getList(1);
  }
};
</script>
