<template>
  <el-tabs>
    <!-- <el-tab-pane :label="$t('Dashboard')">
      <pod-conditions :conditions="$attrs.data.status.pod.conditions" />

      <el-radio-group v-model="monitorType" size="small" class="monitor-type">
        <el-radio-button label="Prometheus">
          {{ $t("historical") }}
        </el-radio-button>
        <el-radio-button label="Netdata">
          {{ $t("realtime") }}
        </el-radio-button>
      </el-radio-group>

      <div v-if="monitorType === 'Prometheus'">
        <pod-metrics />
      </div>

      <div v-if="monitorType === 'Netdata'">
        <netdata :id="netdataId" :node="netdataNode" :podValid="$attrs.data.status.pod.phase" />
      </div>
    </el-tab-pane> -->

    <el-tab-pane :label="$t('monitor')" lazy>
      <pod-conditions :conditions="$attrs.data.status.pod.conditions" />
      <monitor-wrapper />
    </el-tab-pane>

    <el-tab-pane label="YAML">
      <yaml-container :text="$attrs.data" />
    </el-tab-pane>

    <el-tab-pane :label="$t('resourceRelated')">
      <el-tabs type="card">
        <el-tab-pane :label="$t('container')">
          <container
            :containers="$attrs.data.spec.pod.spec.containers"
            :status="$attrs.data.status.pod.containerStatuses"
            :pod="$attrs.data.metadata.name"
            :namespace="namespace"
            :vendor="vendor"
            :region="region"
            :cluster="cluster"
          />
        </el-tab-pane>

        <el-tab-pane :label="$t('ConfigMap')">
          <config-map :data="$attrs.data.spec.configMaps" type="ConfigMap" />
        </el-tab-pane>

        <el-tab-pane :label="$t('Secret')">
          <config-map :data="$attrs.data.spec.secretMaps" type="Secret" />
        </el-tab-pane>

        <el-tab-pane :label="$t('PersistentVolumeClaims')">
          <pvc :pvc="$attrs.data.spec.persistentVolumeClaims" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
    <el-tab-pane :label="$t('Event')">
      <event type="pod" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Container from "@/pages/Detail/components/Container";
import PodConditions from "@/pages/Detail/components/PodConditions";
import ConfigMap from "@/pages/Detail/components/ConfigMap";
import Pvc from "@/pages/Detail/components/Pvc";
import Event from "@/pages/Detail/components/Event";
import Netdata from "@/pages/Detail/components/Netdata";
import PodMetrics from "@/pages/Detail/components/PodMetrics";
import MonitorWrapper from "@/pages/Detail/components/MonitorWrapper";

export default {
  components: {
    Container,
    PodConditions,
    ConfigMap,
    Pvc,
    Event,
    Netdata,
    PodMetrics,
    MonitorWrapper
  },

  data() {
    return {
      netdataId: "",
      netdataNode: "",
      monitorType: "Prometheus"
    };
  },

  mounted() {
    let namespace = this.$attrs.data.metadata.namespace.replace(/-/g, "_");
    let podName = this.$attrs.data.metadata.name.replace(/-/g, "_");
    let uid = this.$attrs.data.metadata.uid.replace(/-/g, "_");
    let containerName = this.$attrs.data.spec.pod.spec.containers[0].name.replace(/-/g, "_");

    this.netdataId = `cgroup_k8s_${namespace}_${podName}_${uid}_${containerName}`;
    this.netdataNode = this.$attrs.data.spec.pod.spec.nodeName;
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    }
  }
};
</script>

<style lang="scss" scoped>
.monitor-type {
  position: absolute;
  left: 0;
  top: 50;
}
</style>
