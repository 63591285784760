<template>
  <el-tabs>
    <el-tab-pane :label="$t('detail')">
      <ingress-topo :data="$attrs.data" />
    </el-tab-pane>

    <el-tab-pane label="YAML">
      <yaml-container :text="$attrs.data" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import IngressTopo from "@/pages/Detail/components/IngressTopo";
export default {
  components: { IngressTopo },

  data() {
    return {
      series: []
    };
  },
  mounted() {
    this.series.push({ name: this.$attrs.data.metadata.name, children: [] });
    this.$attrs.data.spec.rules.forEach(item => {
      if ("http" in item && "host" in item) {
        item.http.paths.forEach(path => {
          this.series[0].children.push({
            name: `${item.host}${path.path} (${path.backend.serviceName}:${path.backend.servicePort}) `
          });
        });
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
</style>
