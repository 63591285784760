<template>
  <div>
    <div v-if="$attrs.pvc">
      <div v-for="item in $attrs.pvc" :key="item.container">
        <el-alert show-icon :title="$t('mountContainer') + item.container" type="success"> </el-alert>

        <el-table :data="item.persistentVolumeClaims">
          <el-table-column :label="$t('claimRef')" prop="claimName" sortable min-width="150">
            <template slot-scope="scope">
              <div class="table-name-info">
                <svg-icon icon-class="PersistentVolumeClaims"></svg-icon>
                <div class="content">
                  <a @click="toDetail(scope.row.claimName)">
                    {{ scope.row.claimName }}
                  </a>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('storage')">
            <template slot-scope="scope">
              {{ scope.row.pvcSpec.resources.requests ? scope.row.pvcSpec.resources.requests.storage : "-" }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('accessModes')">
            <template slot-scope="scope">
              {{ scope.row.pvcSpec.accessModes ? scope.row.pvcSpec.accessModes[0] : "-" }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('PersistentVolume')">
            <template slot-scope="scope">
              {{ scope.row.pvcSpec.volumeName || "-" }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('volumeMode')">
            <template slot-scope="scope">
              {{ scope.row.pvcSpec.volumeMode || "-" }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('storageClassName')">
            <template slot-scope="scope">
              {{ scope.row.pvcSpec.storageClassName || "-" }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div v-else class="empty-text">
      {{ $t("empty") }}
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toDetail(name) {
      this.$router.push({
        path: `/detail/PersistentVolumeClaims/${name}`,
        query: { ...this.$route.query }
      });
    }
  }
};
</script>
