<template>
  <div style="margin-bottom: 10px;">
    <div class="resource-title">{{ $t("location") }}</div>
    <div class="name-value" v-if="namespace">
      <span class="name">{{ $t("Namespace") }}：</span>
      <a href="javascript:;" @click="toNamespace">{{ namespace }}</a>
    </div>

    <div class="name-value">
      <span class="name">{{ $t("Cluster") }}：</span>
      <a href="javascript:;" @click="toClusterMonitor">{{ cluster }}</a>
    </div>

    <div class="name-value">
      <span class="name">{{ $t("region") }}：</span>
      <span class="value">{{ region }}</span>
    </div>

    <div class="name-value">
      <span class="name">{{ $t("vendor") }}：</span>
      <span class="value">{{ vendor }}</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toNamespace() {
      let query = { vendor: this.vendor, region: this.region, cluster: this.cluster };

      this.$router.push({
        path: `/detail/Namespace/${this.namespace}`,
        query
      });
    },

    toClusterMonitor() {
      let query = { cluster: this.cluster };

      this.$router.push({
        path: `/cluster-dashboard`,
        query
      });
    }
  },

  computed: {
    namespace() {
      return this.$route.query.namespace;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    region() {
      return this.$route.query.region;
    },

    vendor() {
      return this.$route.query.vendor;
    }
  }
};
</script>

<style></style>
