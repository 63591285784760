<template>
  <div>
    <el-timeline>
      <el-timeline-item
        v-for="(item, index) in list"
        :key="index"
        :type="item.type === 'Warning' ? 'warning' : 'primary'"
      >
        <div class="name-value">
          <span class="name">{{ $t("time") }}: </span>
          <span class="value">{{ moment(item.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss") }}</span>
        </div>

        <div class="name-value">
          <span class="name">{{ $t("type") }}: </span>
          <span class="value">{{ item.type }}</span>
        </div>
        <!-- 
        <div class="name-value">
          <span class="name">事件源: </span>
          <span class="value">{{ item.involvedObject.name }}</span>
        </div> -->

        <div class="name-value">
          <span class="name">{{ $t("reason") }}: </span>
          <span class="value">{{ item.reason }}</span>
        </div>

        <div class="name-value">
          <span class="name">{{ $t("message") }}: </span>
          <span class="value">{{ item.message }}</span>
        </div>
      </el-timeline-item>
    </el-timeline>

    <div v-if="list.length == 0" class="empty-text">
      {{ $t("empty") }}
    </div>
  </div>
</template>

<script>
import { applicationEvent } from "api/deployment";
import moment from "moment";
import { nodeEvent } from "api/cluster";

export default {
  props: {
    type: {
      type: String
    }
  },

  data() {
    return {
      list: []
    };
  },

  methods: {
    eventList() {
      let params = {};
      let data = {};
      let method = null;

      if (this.type == "application" || this.type == "pod") {
        method = applicationEvent;
        data = { application: this.$route.params.name, ...this.$route.query };
        params = { type: this.$route.params.type };
      }

      if (this.type == "node") {
        method = nodeEvent;
        data = { node: this.$route.params.name, ...this.$route.query };
        params = {};
      }

      method(data, params).then(response => {
        if (response.code === 0) {
          this.list = response.data.items;

          this.list.forEach(item => {
            this.$set(item, "unixTime", moment(item.metadata.creationTimestamp).unix());
          });

          this.list = this.list.sort(function(a, b) {
            return b.unixTime - a.unixTime;
          });
        }
      });
    },

    moment
  },

  mounted() {
    this.eventList();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.event-message {
  font-size: 12px;

  span {
    color: $color-sub;
  }

  div {
    color: $color-main;
  }
}
</style>
