<template>
  <el-tabs>
    <el-tab-pane :label="$t('detail')">
      <div
        class="location"
        v-if="
          $attrs.data.spec.type === 'NodePort' &&
            $attrs.data.metadata.annotations &&
            $attrs.data.metadata.annotations['ingress-host']
        "
      >
        <div class="resource-title">{{ $t("LoadBalancer") }}</div>

        <a @click.prevent="toLocation($attrs.data.metadata.annotations['ingress-host'])">
          <i class="el-icon-paperclip"></i>
          {{ $attrs.data.metadata.annotations["ingress-host"] }}
        </a>
      </div>

      <div class="location" v-if="$attrs.data.spec.type === 'LoadBalancer' && $attrs.data.status.loadBalancer.ingress">
        <div class="resource-title">{{ $t("LoadBalancer") }}</div>

        <a @click.prevent="toLocation($attrs.data.status.loadBalancer.ingress[0].hostname)">
          <i class="el-icon-paperclip"></i>
          {{ $attrs.data.status.loadBalancer.ingress[0].hostname }}
        </a>
      </div>

      <div class="resource-title">{{ $t("servicePort") }}</div>

      <div v-for="item in $attrs.data.spec.ports" :key="item.name">
        <el-popover ref="popover" placement="top" width="320">
          <img :src="portSVG" />

          <span slot="reference">
            <a href="javascript:;">{{ $t("servicePort") }}:</a>
          </span>
        </el-popover>

        <span>
          {{ item.port ? item.port : "-" }}
          <i class="el-icon-document-copy" v-clipboard:copy="item.port"> </i>
        </span>

        <span> / </span>

        <el-popover ref="popover" placement="top" width="320">
          <img :src="nodeportSVG" />

          <span slot="reference">
            <a href="javascript:;">{{ $t("nodePort") }}:</a>
          </span>
        </el-popover>

        <span>
          {{ item.nodePort ? item.nodePort : "-" }}
          <i class="el-icon-document-copy" v-clipboard:copy="item.nodePort"> </i>
        </span>

        <span> / </span>

        <el-popover ref="popover" placement="top" width="320">
          <img :src="containerPortSVG" />

          <span slot="reference">
            <a href="javascript:;">{{ $t("targetPort") }}:</a>
          </span>
        </el-popover>

        <span>
          {{ item.targetPort ? item.targetPort : "-" }}
          <i class="el-icon-document-copy" v-clipboard:copy="item.targetPort"> </i>
        </span>
      </div>
    </el-tab-pane>

    <el-tab-pane label="YAML">
      <yaml-container :text="$attrs.data" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import containerPortSVG from "@/assets/port/container-port.svg";
import portSVG from "@/assets/port/port.svg";
import nodeportSVG from "@/assets/port/nodeport.svg";

export default {
  data() {
    return {
      containerPortSVG,
      portSVG,
      nodeportSVG
    };
  },

  methods: {
    toLocation(href) {
      window.open(`http://${href}`, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.location {
  font-size: 12px;
  span {
    color: $color-sub;
  }

  margin-bottom: 3 * $grid-height;
}
</style>
