<template>
  <div @click="handle">
    <div v-if="unschedulable">
      {{ $t("handle.nodeResume") }}
    </div>

    <div v-else>
      {{ $t("handle.nodePause") }}
    </div>
  </div>
</template>

<script>
import { nodeSchedulable } from "api/cluster";
export default {
  props: {
    unschedulable: {
      type: Boolean
    }
  },
  methods: {
    handle() {
      this.$confirm(
        this.unschedulable
          ? this.$t("recover", [this.$route.params.name])
          : this.$t("unschedulable", [this.$route.params.name]),
        this.$t("prompt"),
        {
          dangerouslyUseHTMLString: true,
          type: "warning"
        }
      )
        .then(() => {
          nodeSchedulable(
            { ...this.$route.query, name: this.$route.params.name },
            { action: this.unschedulable ? "recover" : "unschedulable" }
          ).then(response => {
            if (response.code === 0) {
              this.$emit("update");
              this.$notify({
                title: "Success",
                message: "Success",
                type: "success"
              });
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
