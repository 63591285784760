<template>
  <el-tabs>
    <el-tab-pane :label="$t('resourceRelated')">
      <el-tabs type="card">
        <el-tab-pane :label="$t('nodeList')">
          <node-list :vendor="query.vendor" :region="query.region" :cluster="query.cluster" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import NodeList from "../components/NodeList";

export default {
  components: {
    NodeList
  },

  data() {
    return {};
  },

  computed: {
    query() {
      return this.$route.query;
    }
  }
};
</script>

<style></style>
