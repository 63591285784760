<template>
  <el-tabs>
    <el-tab-pane label="YAML">
      <yaml-container :text="$attrs.data" />
    </el-tab-pane>

    <el-tab-pane :label="$t('detail')">
      <el-tabs type="border-card">
        <el-tab-pane v-for="(value, key) in $attrs.data.data" :key="key" :label="key">
          <yaml-container :text="value" :transfer="false" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {};
</script>
