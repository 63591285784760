<template>
  <div>
    <div v-if="data">
      <el-table :data="data">
        <el-table-column prop="container" :label="$t('container')"> </el-table-column>
        <el-table-column :label="$t('ConfigMap')">
          <template slot-scope="scope">
            <div v-for="(conf, index) in scope.row.configs" :key="index">
              <a @click="toDetail(conf.name)">
                {{ conf.name }}
              </a>

              <div v-for="(item, index) in conf.items" :key="index">
                <div class="title">
                  {{ $t("configMapItem") }}: {{ item.key }}
                  <i class="el-icon-document-copy" v-clipboard:copy="item.key"></i>
                </div>
                <div class="desc">
                  {{ $t("mountPath") }}: {{ item.path }}
                  <i class="el-icon-document-copy" v-clipboard:copy="item.path"></i>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else class="empty-text">
      {{ $t("empty") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    type: String
  },

  methods: {
    toDetail(name) {
      this.$router.push({
        path: `/detail/${this.type}/${name}`,
        query: { ...this.$route.query }
      });
    }
  }
};
</script>
