<template>
  <div>
    <div @click="edit">
      {{ $t("handle.edit") }}
    </div>

    <el-dialog
      :title="$t('handle.edit')"
      :visible.sync="dialogVisible"
      top="30px"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="800px"
    >
      <el-form size="small" label-position="top">
        <el-form-item :label="$t('annotations')">
          <edit-labels :data="$attrs.data.metadata.annotations" ref="editLabelForm" />
        </el-form-item>

        <item
          :data="$attrs.data"
          ref="ingressItem"
          :vendor="vendor"
          :region="region"
          :cluster="cluster"
          :namespace="$attrs.data.metadata.namespace"
        />
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="loading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Item from "./Item";

import { ingressUpdate } from "api/ingress";
import { transform } from "lodash";

export default {
  components: {
    Item
  },

  data() {
    return {
      dialogVisible: false,
      loading: false
    };
  },

  methods: {
    edit() {
      this.dialogVisible = true;
    },

    submit() {
      this.$refs["editLabelForm"].$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;

          let ingressForm = {
            metadata: {
              name: this.$attrs.data.metadata.name,
              namespace: this.$attrs.data.metadata.namespace,
              annotations: {}
            },
            spec: {
              tls: [],
              rules: []
            }
          };

          ingressForm.metadata.annotations = transform(
            this.$refs["editLabelForm"].form.labels,
            function(result, item) {
              result[item.key] = item.value;
            },
            {}
          );

          ingressForm.spec.rules = this.$refs["ingressItem"].rules;

          this.$refs["ingressItem"].rules.forEach(item => {
            if (item.host && item.tls) {
              ingressForm.spec.tls.push({
                hosts: [item.host],
                secretName: item.secretName
              });
            }
          });

          ingressUpdate(ingressForm, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
            response => {
              this.loading = false;

              if (response.code === 0) {
                this.$emit("update");
                this.dialogVisible = false;

                this.$notify({
                  title: "Success",
                  message: "Success",
                  type: "success"
                });
              }
            }
          );
        }
      });
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    }
  }
};
</script>
