<template>
  <el-tabs>
    <el-tab-pane :label="$t('resourceRelated')" :lazy="true">
      <el-tabs type="card">
        <el-tab-pane :label="$t('pod')" :lazy="true">
          <pod type="node" :vendor="vendor" :region="region" :cluster="cluster" :name="node" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>
    <el-tab-pane :label="$t('monitor')" lazy>
      <monitor-wrapper />
    </el-tab-pane>

    <el-tab-pane label="YAML">
      <yaml-container :text="$attrs.data" />
    </el-tab-pane>

    <el-tab-pane :label="$t('Event')">
      <event type="node" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Pod from "@/pages/Detail/components/Pod";
import Event from "@/pages/Detail/components/Event";
// import NodeMetrics from "@/pages/Detail/components/NodeMetrics";
import MonitorWrapper from "@/pages/Detail/components/MonitorWrapper";

export default {
  components: {
    Pod,
    Event,
    MonitorWrapper
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    node() {
      return this.$route.params.name;
    }
  }
};
</script>
