<template>
  <div @click="toCollect">
    <div class="handle-button" v-if="!collected">
      <svg-icon icon-class="unstar" class-name="handle-icon"></svg-icon>
      {{ $t("handle.collect") }}
    </div>

    <div class="handle-button" v-if="collected">
      <svg-icon icon-class="star" class-name="handle-icon"></svg-icon>
      {{ $t("handle.cancelCollect") }}
    </div>
  </div>
</template>

<script>
import { addCollect, isCollected, cancelCollect } from "api/collection";
export default {
  data() {
    return {
      collected: false
    };
  },

  methods: {
    toCollect() {
      this.$confirm(
        this.collected
          ? this.$t("cancelCollect", [this.$route.params.name])
          : this.$t("collect", [this.$route.params.name]),
        this.$t("prompt"),
        {
          dangerouslyUseHTMLString: true,
          type: "warning"
        }
      )
        .then(() => {
          let action = this.collected ? cancelCollect : addCollect;

          action({ ...this.$route.query, ...this.$route.params }).then(response => {
            if (response.code === 0) {
              this.collected = !this.collected;
            }
          });
        })
        .catch(() => {});
    },

    isCollected() {
      isCollected({ ...this.$route.query, ...this.$route.params }).then(response => {
        if (response.code === 0) {
          this.collected = response.data;
        }
      });
    }
  },

  mounted() {
    this.isCollected();
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  }
};
</script>
