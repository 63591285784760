<template>
  <div v-loading="loading">
    <el-table :data="list">
      <el-table-column :label="$t('nodeName')" prop="name" sortable min-width="200">
        <template slot-scope="scope">
          <div class="table-name-info">
            <svg-icon icon-class="PersistentVolumeClaims"></svg-icon>
            <div class="content">
              <a @click.prevent="toPvc(scope.row.metadata)">
                {{ scope.row.metadata.name }}
              </a>
              <span class="desc">
                {{ moment(scope.row.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss") }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('storage')" prop="spec.resources.requests.storage"> </el-table-column>

      <el-table-column :label="$t('accessModes')" prop="spec.accessModes[0]"> </el-table-column>

      <el-table-column :label="$t('storageClassName')" prop="spec.storageClassName"> </el-table-column>

      <el-table-column :label="$t('volumeMode')" prop="spec.volumeMode"> </el-table-column>

      <el-table-column :label="$t('status')" prop="status.phase"> </el-table-column>
    </el-table>

    <el-pagination
      layout="prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { pvRelatedPvc } from "api/pv";
import moment from "moment";

export default {
  props: {
    type: {
      type: String
    }
  },

  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      currentPage: 1,
      currentPageSize: 10,
      chartTitle: {}
    };
  },

  methods: {
    moment,

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    },

    getList(page = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = page;

      pvRelatedPvc(
        {
          ...this.$route.query,
          persistentvolume: this.$route.params.name
        },
        { page, pageSize }
      ).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.total = response.data.total;
          this.list = response.data.items;
        }
      });
    },

    toPvc(metadata) {
      let { name, namespace } = metadata;
      let { vendor, region, cluster } = this.$route.query;
      this.$router.push({
        path: `/detail/PersistentVolumeClaims/${name}`,
        query: { vendor, region, cluster, namespace }
      });
    }
  },

  mounted() {
    this.getList();
  }
};
</script>
