<template>
  <div class="loading-pre-container">
    <el-radio-group v-model="sortby" size="small" style="margin: 10px 0;" @change="namespaceApplicationRank">
      <el-radio-button label="cpu">
        {{ $t("rankByCPU") }}
      </el-radio-button>
      <el-radio-button label="memory">
        {{ $t("rankByMemory") }}
      </el-radio-button>
    </el-radio-group>

    <div v-loading="loading">
      <div v-if="!loading">
        <div v-for="(item, index) in data" :key="index">
          <div class="g2-chart-title">{{ item.title }}</div>
          <g2-plot :option="mapOption(item)" type="GroupedColumn" v-if="item.data.length > 0" />
          <result type="empty" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { namespaceApplicationRank } from "api/monitor";
import { find } from "lodash";
import Result from "@/components/Result.vue";
export default {
  props: {
    vendor: {
      type: String
    },

    region: {
      type: String
    },

    cluster: {
      type: String
    },

    namespace: {
      type: String
    }
  },

  components: { Result },

  data() {
    return {
      loading: true,
      data: [],
      sortby: "cpu"
    };
  },

  methods: {
    namespaceApplicationRank() {
      this.loading = true;

      namespaceApplicationRank({
        namespace: this.namespace,
        vendor: this.vendor,
        region: this.region,
        cluster: this.cluster
      }).then(response => {
        if (response.code === 0) {
          let { data: deploymentCPU } = this.getTargetData(response.data, "deployment_cpu_limit", "CPU");

          let { data: deploymentMemory } = this.getTargetData(response.data, "deployment_memory_limit", "Memory");

          let { data: stsCPU } = this.getTargetData(response.data, "sts_cpu_limit", "CPU");
          let { data: stsMemory } = this.getTargetData(response.data, "sts_memory_limit", "Memory");

          let { data: cronjobCPU } = this.getTargetData(response.data, "cronjob_cpu_limit", "CPU");
          let { data: cronjobMemory } = this.getTargetData(response.data, "cronjob_memory_limit", "Memory");

          let { data: dsCPU } = this.getTargetData(response.data, "ds_cpu_limit", "CPU");
          let { data: dsMemory } = this.getTargetData(response.data, "ds_memory_limit", "Memory");

          let deploymentData = [];
          let stsData = [];
          let cronjobData = [];
          let dsData = [];

          if (this.sortby === "cpu") {
            deploymentData.push(...deploymentCPU, ...deploymentMemory);
            stsData.push(...stsCPU, ...stsMemory);
            cronjobData.push(...cronjobCPU, ...cronjobMemory);
            dsData.push(...dsCPU, ...dsMemory);
          }

          if (this.sortby === "memory") {
            deploymentData.push(...deploymentMemory, ...deploymentCPU);
            stsData.push(...stsMemory, ...stsCPU);
            cronjobData.push(...cronjobMemory, ...cronjobCPU);
            dsData.push(...dsMemory, ...dsCPU);
          }

          this.data = [
            {
              title: this.$t("deploymentRank"),
              data: [...deploymentData]
            },
            {
              title: this.$t("statefulSetRank"),
              data: [...stsData]
            },
            {
              title: this.$t("cronJobRank"),
              data: [...cronjobData]
            },
            {
              title: this.$t("daemonSetRank"),
              data: [...dsData]
            }
          ];

          this.loading = false;
        }
      });
    },

    getTargetData(data, target, type) {
      let model = find(data, { name: target }) ? find(data, { name: target }).model : [];
      let unit = find(data, { name: target }) ? find(data, { name: target }).unit : "";
      let result = [];

      if (model.length > 0) {
        model.forEach(item => {
          result.push({
            DataType: `${type}(${unit})`,
            Name: item.metric ? item.metric.owner_name : "",
            Usage: Number(Number(item.value[1]).toFixed(2))
          });
        });
      }

      return { data: result, unit };
    },

    mapOption(item) {
      return {
        forceFit: true,
        data: item.data,
        height: 320,
        xField: "Name",
        padding: "auto",
        yField: "Usage",
        yAxis: {
          min: 0
        },
        label: {
          visible: true
        },

        groupField: "DataType"
      };
    }
  },

  mounted() {
    this.namespaceApplicationRank();
  }
};
</script>
