<template>
  <div v-loading="loading" class="loading-pre-container" style="padding-bottom: 50px;">
    <chart-tool
      :visibleObj="{ timeRange: true, interval: true }"
      :interval.sync="chartInterval"
      :timeRange.sync="timeRange"
    />

    <div class="default-panel-wrapper" v-for="item in layoutData" :key="item.i">
      <div class="panel-title">
        {{ item.title }}
        <el-popover placement="bottom" width="400" trigger="hover">
          <i slot="reference" class="el-icon-info"></i>
          <div>
            <div class="title">PromQL:</div>

            <div class="desc" v-for="(ql, index) in item.panelQLs" :key="index">
              {{ ql.query }}
            </div>
          </div>
        </el-popover>
      </div>

      <div class="panel-chart">
        <chart-panel
          :timeRange="timeRange"
          :ref="'chart-panel-' + item.i"
          :metric-info="item"
          :auto-refresh="chartInterval"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defaultDashboard } from "api/monitor";
import ChartTool from "@/components/ChartTool";
import chartTool from "@/mixins/chart-tool";
import ChartPanel from "./ChartPanel";

export default {
  components: {
    ChartTool,
    ChartPanel
  },

  mixins: [chartTool],

  data() {
    return {
      chartList: [],
      loading: true,
      chartType: "Area",
      layoutData: [],
      emptyNum: 0
    };
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    name() {
      return this.$route.params.name;
    }
  },

  methods: {
    init() {
      this.loading = true;

      defaultDashboard(
        {
          vendor: this.vendor,
          region: this.region,
          cluster: this.cluster
        },
        {
          type: this.type,
          name: this.name,
          namespace: this.namespace
        }
      ).then(response => {
        if (response.code === 0) {
          this.layoutData = response.data;
          this.layoutData.forEach((item, index) => {
            item.i = index;
          });
          this.loading = false;
        }
      });
    }
  },

  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.default-panel-wrapper {
  position: relative;
  height: 300px;
  background-color: #fff;
  border: 1px solid #dce1e6;
  border-radius: 3px;
  margin-bottom: 10px;

  .panel-title {
    height: 28px;
    line-height: 28px;
    @include title(14px);
    text-align: center;

    i {
      cursor: pointer;
    }
  }

  .panel-chart {
    height: calc(100% - 28px);
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
  }
}
</style>
