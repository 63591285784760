<template>
  <div>
    <div @click="edit">
      {{ $t("handle.edit") }}
    </div>

    <el-dialog
      :title="$t('handle.edit')"
      :visible.sync="dialogVisible"
      top="30px"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="800px"
    >
      <div class="title">
        当前配置文件类型为：
      </div>

      <div class="title">
        <span v-if="isBinary">
          二进制类型，在提交时经过base64转换，配置项的值不允许手动修改，适用于证书等二进制文件
        </span>
        <span v-else>基本类型，在提交时不会经过base64转换，配置项的值允许手动修改，适用于文本等普通配置文件</span>
      </div>

      <item-list :data="$attrs.data.data" :is-binary="isBinary" ref="configItem" />

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="editLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ItemList from "./ItemList";
import { configMapUpdate } from "api/configmap";
import { secretUpdate } from "api/secret";
export default {
  components: {
    ItemList
  },

  data() {
    return {
      dialogVisible: false,
      editLoading: false
    };
  },

  methods: {
    edit() {
      this.dialogVisible = true;
    },

    submit() {
      let data = {
        data: {},
        kind: "",
        metadata: {
          labels: {
            app: this.$route.params.name
          },
          name: this.$route.params.name,
          namespace: this.$route.query.namespace,
          annotations: this.$attrs.data.metadata.annotations
        }
      };

      let action = null;

      if (this.$route.params.type == "ConfigMap") {
        data["kind"] = "ConfigMap";
        data.data = {};
        this.$refs["configItem"].list.forEach(item => {
          data.data[item.key] = item.value;
        });

        action = configMapUpdate;
      } else {
        data["kind"] = "Secret";
        data.stringData = {};
        this.$refs["configItem"].list.forEach(item => {
          data.stringData[item.key] = item.value;
        });

        action = secretUpdate;
      }

      this.editLoading = true;

      action(data, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(response => {
        this.editLoading = false;
        if (response.code === 0) {
          this.$emit("update");
          this.dialogVisible = false;

          this.$notify({
            title: "Success",
            message: "Success",
            type: "success"
          });
        }
      });
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    isBinary() {
      let key = this.type == "ConfigMap" ? "configmap.kubestar.io/type" : "secrets.kubestar.io/type";

      if (this.$attrs.data.metadata.annotations && this.$attrs.data.metadata.annotations[key] == "binary") {
        return true;
      }

      return false;
    }
  }
};
</script>
