<template>
  <div>
    <div @click="edit">
      {{ $t("handle.edit") }}
    </div>

    <el-dialog
      :title="$t('handle.edit')"
      :visible.sync="dialogVisible"
      top="30px"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="800px"
    >
      <el-form :model="form" ref="nsForm" size="small" label-position="top">
        <el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item :label="$t('resourceQuota') + ' - ' + $t('cpu')">
                <el-input v-model.number="form.spec.hard.cpu">
                  <span slot="append">Core</span>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="$t('resourceQuota') + ' - ' + $t('memory')">
                <el-input v-model.number="form.spec.hard.memory">
                  <span slot="append">G</span>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item :label="$t('limitRange') + ' - ' + item.type" v-for="item in form.spec.limits" :key="item.type">
          <el-row :gutter="20" style="margin-bottom: 10px;">
            <el-col :span="12">
              <el-input v-model="item.max.cpu">
                <template slot="prepend">CPU Max</template>

                <el-select v-model="item.max.cpuUnit" slot="append" style="width: 90px;">
                  <el-option label="Core" value="Core"></el-option>
                  <el-option label="m" value="m"></el-option>
                </el-select>
              </el-input>
            </el-col>

            <el-col :span="12">
              <el-input v-model="item.max.memory">
                <template slot="prepend">Memory Max</template>

                <el-select v-model="item.max.memoryUnit" slot="append" style="width: 90px;">
                  <el-option label="K" value="K"></el-option>
                  <el-option label="M" value="M"></el-option>
                  <el-option label="G" value="G"></el-option>
                </el-select>
              </el-input>
            </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-bottom: 10px;">
            <el-col :span="12">
              <el-input v-model="item.min.cpu">
                <template slot="prepend">CPU Min</template>

                <el-select v-model="item.min.cpuUnit" slot="append" style="width: 90px;">
                  <el-option label="Core" value="Core"></el-option>
                  <el-option label="m" value="m"></el-option>
                </el-select>
              </el-input>
            </el-col>

            <el-col :span="12">
              <el-input v-model="item.min.memory">
                <template slot="prepend">Memory Min</template>

                <el-select v-model="item.min.memoryUnit" slot="append" style="width: 90px;">
                  <el-option label="K" value="K"></el-option>
                  <el-option label="M" value="M"></el-option>
                  <el-option label="G" value="G"></el-option>
                </el-select>
              </el-input>
            </el-col>
          </el-row>

          <el-row :gutter="20" v-if="item.type == 'Container'">
            <el-col :span="12">
              <el-input v-model="item.default.cpu">
                <template slot="prepend">CPU Default</template>

                <el-select v-model="item.default.cpuUnit" slot="append" style="width: 90px;">
                  <el-option label="Core" value="Core"></el-option>
                  <el-option label="m" value="m"></el-option>
                </el-select>
              </el-input>
            </el-col>

            <el-col :span="12">
              <el-input v-model="item.default.memory">
                <template slot="prepend">Memory Default</template>

                <el-select v-model="item.default.memoryUnit" slot="append" style="width: 90px;">
                  <el-option label="K" value="K"></el-option>
                  <el-option label="M" value="M"></el-option>
                  <el-option label="G" value="G"></el-option>
                </el-select>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="loading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { resourceQuotaUpdate, limitrangesUpdate } from "api/namespace";

import { includes } from "lodash";

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      form: {
        metadata: {
          name: "",
          namespace: ""
        },

        spec: {
          hard: {
            cpu: "",
            memory: ""
          },

          limits: [
            {
              type: "Pod",
              max: {
                cpu: "",
                memory: ""
              },
              min: {
                cpu: "",
                memory: ""
              }
            },

            {
              type: "Container",
              max: {
                cpu: "",
                memory: ""
              },
              min: {
                cpu: "",
                memory: ""
              },
              default: {
                cpu: "",
                memory: ""
              }
            }
          ]
        }
      }
    };
  },

  methods: {
    edit() {
      this.dialogVisible = true;
    },

    async submit() {
      this.loading = true;

      await this.resourceQuotaUpdate();
      await this.limitRangeUpdate();

      this.loading = false;
      this.$emit("update");
      this.dialogVisible = false;
    },

    async resourceQuotaUpdate() {
      let response = await resourceQuotaUpdate({
        metadata: {
          name: this.form.metadata.name,
          namespace: this.form.metadata.name
        },

        spec: {
          hard: {
            cpu: this.form.spec.hard.cpu,
            memory: this.form.spec.hard.memory ? this.form.spec.hard.memory + "G" : ""
          }
        }
      });

      return response;
    },

    async limitRangeUpdate() {
      this.form.spec.limits.forEach(item => {
        if (item.max.cpu && !includes(item.max.cpu, item.max.cpuUnit) && item.max.cpuUnit != "Core")
          item.max.cpu += item.max.cpuUnit;

        if (item.max.memory && !includes(item.max.memory, item.max.memoryUnit)) item.max.memory += item.max.memoryUnit;

        if (item.min.cpu && !includes(item.min.cpu, item.min.cpuUnit) && item.min.cpuUnit != "Core")
          item.min.cpu += item.min.cpuUnit;

        if (item.min.memory && !includes(item.min.memory, item.min.memoryUnit)) item.min.memory += item.min.memoryUnit;

        if (item.default) {
          if (item.default.cpu && !includes(item.default.cpu, item.default.cpuUnit) && item.default.cpuUnit != "Core")
            item.default.cpu += item.default.cpuUnit;
          if (item.default.memory && !includes(item.default.memory, item.default.memoryUnit))
            item.default.memory += item.default.memoryUnit;

          delete item.default.cpuUnit;
          delete item.default.memoryUnit;
        }

        delete item.max.cpuUnit;
        delete item.max.memoryUnit;

        delete item.min.cpuUnit;
        delete item.min.memoryUnit;
      });

      let response = await limitrangesUpdate(
        {
          metadata: {
            name: this.form.metadata.name,
            namespace: this.form.metadata.name
          },

          spec: {
            limits: this.form.spec.limits
          }
        },
        { vendor: this.vendor, region: this.region, cluster: this.cluster }
      );

      return response;
    },

    parseUnit(item) {
      if (includes(item.cpu, "m")) {
        this.$set(item, "cpuUnit", "m");
      } else {
        this.$set(item, "cpuUnit", "Core");
      }

      item.cpu = parseInt(item.cpu);

      let memoryUnit = ["G", "M", "K"];

      for (let unit of memoryUnit) {
        if (includes(item.memory, unit)) {
          this.$set(item, "memoryUnit", unit);
          break;
        }
      }

      item.memory = parseInt(item.memory);
    }
  },

  mounted() {
    this.form.metadata.name = this.$attrs.data.metadata.name;
    this.form.metadata.namespace = this.$attrs.data.metadata.name;

    if (this.$attrs.data.spec.limitRange.limits) {
      this.$attrs.data.spec.limitRange.limits.forEach(item => {
        this.parseUnit(item.max);
        this.parseUnit(item.min);

        if (item.default) {
          this.parseUnit(item.default);
        }
      });

      this.form.spec.limits = this.$attrs.data.spec.limitRange.limits;
    }

    if (this.$attrs.data.spec.resourcrQuota.hard) {
      if (this.$attrs.data.spec.resourcrQuota.hard.cpu)
        this.form.spec.hard.cpu = parseInt(this.$attrs.data.spec.resourcrQuota.hard.cpu);

      if (this.$attrs.data.spec.resourcrQuota.hard.memory)
        this.form.spec.hard.memory = parseInt(this.$attrs.data.spec.resourcrQuota.hard.memory);
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    }
  }
};
</script>
