<template>
  <div v-loading="loading" class="loading-pre-container">
    <chart-tool
      :visibleObj="{ timeRange: true, interval: true }"
      :interval.sync="chartInterval"
      :timeRange.sync="timeRange"
      @timeRangeChange="timeRangeChange"
      @intervalChange="intervalChange"
    />

    <el-row :gutter="20" v-if="!loading">
      <el-col :span="12" v-for="item in chartList" :key="item.name">
        <div class="g2-chart-title">{{ $t(item.name) }} {{ item.unit ? `(${item.unit})` : "" }}</div>
        <g2-plot :option="mapOption(item)" type="Area" :ref="item.name" v-if="item.data.length > 0" />
        <result type="empty" v-else />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getStep } from "utils";
import { podMetric } from "api/monitor";
import ChartTool from "@/components/ChartTool";
import moment from "moment";
import chartTool from "@/mixins/chart-tool";
import { sortBy, filter } from "lodash";
import Result from "@/components/Result.vue";

export default {
  components: { ChartTool, Result },
  mixins: [chartTool],

  data() {
    return {
      chartList: [],
      loading: true
    };
  },

  methods: {
    metricTarget() {
      return new Promise((resolve, reject) => {
        let [start, end] = this.timeRange;
        let step = getStep(end - start, 80);
        let type = this.$route.params.type;

        podMetric({ name: this.$route.params.name, ...this.$route.query }, { start, end, step, type }).then(
          response => {
            if (response.code === 0) {
              let data = response.data;
              let result = [];

              let cpuInfo = this.mapChartData(["pod_cpu_limit", "pod_cpu_request", "pod_cpu_used3mavg"], data);
              let memInfo = this.mapChartData(["pod_memory_limit", "pod_memory_request", "pod_memory_used"], data);
              let networkInfo = this.mapChartData(["pod_networki/o3mavg"], data);
              let tcpInfo = this.mapChartData(["pod_tcp_usage_total"], data);

              result.push(
                { name: "pod_cpu_used3mavg", data: cpuInfo.data, unit: cpuInfo.unit },
                { name: "pod_memory_used", data: memInfo.data, unit: memInfo.unit },
                { name: "pod_networki/o3mavg", data: networkInfo.data, unit: networkInfo.unit },
                { name: "pod_tcp_usage_total", data: tcpInfo.data, unit: tcpInfo.unit }
              );

              resolve(result);
            } else {
              reject(response.message);
            }
          }
        );
      });
    },

    init() {
      this.loading = true;
      this.metricTarget().then(data => {
        this.loading = false;
        this.chartList = data;
      });
    },

    mapChartData(targets, data) {
      let result = [];
      let unit = "";

      targets.forEach(target => {
        let targetData = filter(data, { name: target });
        let targetResult = [];
        unit = targetData[0].unit;

        if (targetData[0].model.length > 0) {
          targetData[0].model[0].values.forEach(value => {
            targetResult.push({
              target: this.$t(target),
              value: Number(Number(value[1]).toFixed(3)),
              time: moment(value[0] * 1000).format("YYYY-MM-DD, HH:mm:ss")
            });
          });
        }

        result.push(...targetResult);
      });

      return { data: sortBy(result, "time"), unit };
    },

    mapOption(item) {
      return {
        height: 220,
        forceFit: true,

        data: item.data,
        xField: "time",
        yField: "value",
        smooth: true,

        yAxis: {
          min: 0,
          tickCount: 4,
          label: {
            visible: true
          },
          nice: true
        },

        xAxis: {
          type: "dateTime",
          tickCount: 8,
          grid: {
            visible: true
          },
          label: {
            formatter: value => {
              return moment(value, "YYYY-MM-DD, HH:mm:ss").format("HH:mm");
            }
          }
        },

        legend: {
          position: "top-left",
          flipPage: true,
          marker: {
            style: {
              symbol: "circle",
              fillOpacity: 1
            }
          }
        },

        seriesField: "target"
      };
    },

    intervalMetric() {
      this.interval = setInterval(() => {
        this.metricTarget().then(data => {
          for (let item of data) {
            if (this.$refs[item.name][0]) this.$refs[item.name][0].plot.changeData(item.data);
          }
        });
      }, 10000);
    }
  },

  mounted() {
    this.init();
  }
};
</script>

<style></style>
