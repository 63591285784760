<template>
  <div class="monitor-wrapper">
    <default-monitor />
  </div>
</template>

<script>
import DefaultMonitor from "./DefaultMonitor";

export default {
  components: {
    DefaultMonitor
  },

  data() {
    return {};
  },

  methods: {}
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.monitor-wrapper {
  position: relative;
}
</style>
