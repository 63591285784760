<template>
  <el-card style="margin-bottom: 20px;">
    <div class="search-normal-container clearfix">
      <div class="list-title pull-left">
        {{ $t(type) }}
      </div>

      <div class="search-input-wrapper">
        <el-input
          prefix-icon="el-icon-search"
          v-model="searchText"
          size="mini"
          @keyup.enter.native="getList(1)"
          class="search-input"
        >
        </el-input>

        <el-tooltip effect="dark" :content="$t('handle.refresh')" placement="bottom-start">
          <el-button plain icon="el-icon-refresh" size="mini" @click="getList(currentPage)"></el-button>
        </el-tooltip>
      </div>
    </div>

    <div v-loading="loading">
      <el-table :data="list" max-height="400px">
        <el-table-column :label="$t('application')" prop="metadata.name" sortable width="200">
          <template slot-scope="scope">
            <div class="table-name-info">
              <svg-icon :icon-class="type"></svg-icon>
              <div class="content">
                <a @click.prevent="toDetail(scope.row.metadata.name)">
                  {{ scope.row.metadata.name }}
                </a>
                <span class="desc">
                  {{ moment(scope.row.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss") }}
                </span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('container')" min-width="300">
          <template slot-scope="scope">
            <div v-if="type === 'CronJob'">
              <div
                class="table-name-info"
                v-for="container in scope.row.spec.jobTemplate.spec.template.spec.containers"
                :key="container.name"
              >
                <div class="content">
                  <div class="title">
                    {{ container.name }}
                  </div>
                  <span class="desc" :title="container.image">
                    <i class="el-icon-document-copy" v-clipboard:copy="container.image"></i>
                    {{ container.image }}
                  </span>
                </div>
              </div>
            </div>

            <div v-else>
              <div
                class="table-name-info"
                v-for="container in scope.row.spec.template.spec.containers"
                :key="container.name"
              >
                <div class="content">
                  <div class="title">
                    {{ container.name }}
                  </div>
                  <span class="desc" :title="container.image">
                    <i class="el-icon-document-copy" v-clipboard:copy="container.image"></i>
                    {{ container.image }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('readyReplicas')" prop="status.readyReplicas" v-if="type === 'StatefulSet'">
        </el-table-column>

        <el-table-column :label="$t('currentReplicas')" prop="status.currentReplicas" v-if="type === 'StatefulSet'">
        </el-table-column>

        <el-table-column :label="$t('updatedReplicas')" prop="status.updatedReplicas" v-if="type === 'StatefulSet'">
        </el-table-column>

        <el-table-column :label="$t('currentRevision')" prop="status.currentRevision" v-if="type === 'StatefulSet'">
          <template slot-scope="scope">
            <i class="el-icon-document-copy" v-clipboard:copy="scope.row.status.currentRevision"></i>
            {{ scope.row.status.currentRevision }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('updateRevision')" prop="status.updateRevision" v-if="type === 'StatefulSet'">
          <template slot-scope="scope">
            <i class="el-icon-document-copy" v-clipboard:copy="scope.row.status.updateRevision"></i>
            {{ scope.row.status.updateRevision }}
          </template>
        </el-table-column>

        <el-table-column :label="$t('lastSchedule')" v-if="type === 'CronJob'" width="150">
          <template slot-scope="scope">
            <div>
              {{ moment(scope.row.status.lastScheduleTime).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('schedule')" v-if="type === 'CronJob'" width="100">
          <template slot-scope="scope">
            <div class="title" style="font-size: 14px;">{{ scope.row.spec.schedule }}</div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('status')" v-if="type === 'CronJob'" width="100">
          <template slot-scope="scope">
            <div class="status danger" v-if="scope.row.spec.suspend">Suspended</div>
            <div class="status success" v-else>Scheduled</div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('applicationReady')" v-if="type === 'Deployment'" width="120">
          <template slot-scope="scope">
            {{ scope.row.status.readyReplicas || "-" }}/{{ scope.row.status.replicas || "-" }}
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('daemonSetCurrent')"
          prop="status.currentNumberScheduled"
          v-if="type === 'DaemonSet'"
        >
        </el-table-column>

        <el-table-column
          :label="$t('daemonSetDesired')"
          prop="status.desiredNumberScheduled"
          v-if="type === 'DaemonSet'"
        ></el-table-column>

        <!--  <el-table-column :label="$t('handle.handle')" width="100" >
          <template slot-scope="scope">
            <el-dropdown>
              <i class="el-icon-more-outline el-icon--right"></i>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <update
                    :vendor="vendor"
                    :region="region"
                    :cluster="cluster"
                    :application="scope.row.metadata.name"
                    :namespace="namespace"
                    v-on="$listeners"
                    v-if="['Deployment', 'StatefulSet', 'DaemonSet'].includes(type)"
                  />
                </el-dropdown-item>

                <el-dropdown-item>
                  <rollback
                    :vendor="vendor"
                    :region="region"
                    :cluster="cluster"
                    :application="scope.row.metadata.name"
                    :namespace="namespace"
                    v-on="$listeners"
                    v-if="['Deployment'].includes(type)"
                  />
                </el-dropdown-item>

                <el-dropdown-item>
                  <scale
                    :vendor="vendor"
                    :region="region"
                    :cluster="cluster"
                    :application="scope.row.metadata.name"
                    :namespace="namespace"
                    v-on="$listeners"
                    v-if="['Deployment', 'StatefulSet'].includes(type)"
                  />
                </el-dropdown-item>

                <el-dropdown-item>
                  <auto-scale
                    :vendor="vendor"
                    :region="region"
                    :cluster="cluster"
                    :application="scope.row.metadata.name"
                    :namespace="namespace"
                    v-on="$listeners"
                    v-if="['Deployment'].includes(type)"
                  />
                </el-dropdown-item>

                <el-dropdown-item>
                  <pause
                    :vendor="vendor"
                    :region="region"
                    :cluster="cluster"
                    :application="scope.row.metadata.name"
                    :namespace="namespace"
                    :pause="scope.row.spec.cronJob.suspend"
                    v-on="$listeners"
                    v-if="['CronJob'].includes(type)"
                  />
                </el-dropdown-item>

                <el-dropdown-item>
                  <delete
                    :vendor="vendor"
                    :region="region"
                    :cluster="cluster"
                    :application="scope.row.metadata.name"
                    :namespace="namespace"
                    v-on="$listeners"
                  />
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <el-pagination
      layout="prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </el-card>
</template>

<script>
import { applicationListByNamespace } from "api/deployment";
import moment from "moment";

export default {
  props: {
    type: {
      type: String
    }
  },

  components: {},

  data() {
    return {
      list: [],
      total: 0,
      loading: true,
      currentPage: 1,
      searchText: "",
      currentPageSize: 10
    };
  },

  methods: {
    moment,

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    },

    getList(page = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = page;

      applicationListByNamespace(
        {
          ...this.$route.query,
          namespace: this.$route.params.name
        },
        {
          type: this.type,
          page,
          pageSize,
          search: [this.searchText]
        }
      ).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.list = response.data.items;
          this.total = response.data.total;
        }
      });
    },

    toDetail(name) {
      this.$router.push({
        path: `/detail/${this.type}/${name}`,
        query: { ...this.$route.query, namespace: this.$route.params.name }
      });
    }
  },

  computed: {
    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    }
  },

  mounted() {
    this.getList(1);
  }
};
</script>
