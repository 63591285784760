<template>
  <div v-loading="loading" class="loading-pre-container">
    <div class="desc">
      提示: 查看拓扑图上Service和Pod的详情，请右键点击选择具体的卡片，点击“查看资源详情”，即可跳转到对应资源详情页面
    </div>

    <div id="container"></div>
  </div>
</template>

<script>
import Service from "@/assets/k8s/Service.svg";
import Ingress from "@/assets/k8s/Ingress.svg";
import Host from "@/assets/k8s/Host.svg";
import Pod from "@/assets/k8s/Pod.svg";
import SuccessIcon from "@/assets/success.svg";
import WarnIcon from "@/assets/warning.svg";
import G6 from "@antv/g6";

import { isEmpty, includes } from "lodash";

export default {
  data() {
    return {
      loading: true,
      links: [],
      data: [],
      graph: null,

      SuccessIcon,
      WarnIcon,

      imageObj: {
        Service,
        Ingress,
        Host,
        Pod
      }
    };
  },

  async mounted() {
    await this.mapOption();
    this.drawTopo();
  },

  methods: {
    async mapOption() {
      this.loading = true;

      // 添加Ingress节点
      this.data.push({
        id: this.$attrs.data.metadata.name,
        label: this.ellipsisText(this.$attrs.data.metadata.name),
        value: this.$attrs.data.metadata.name,
        description: "Ingress"
      });

      this.$attrs.data.spec.rules.forEach(item => {
        if ("http" in item && "host" in item) {
          // 添加Host节点
          this.data.push({
            id: item.host,
            label: this.ellipsisText(item.host),
            value: item.host,
            description: "Host"
          });

          this.links.push({
            source: this.$attrs.data.metadata.name,
            target: item.host
          });

          item.http.paths.forEach(path => {
            // serviceName = "ssl-redirect" 忽略
            // 不画图

            if (path.backend.serviceName != "ssl-redirect") {
              !includes(this.uniqueNodeIds, item.host + "/" + path.backend.serviceName) &&
                this.data.push({
                  id: item.host + "/" + path.backend.serviceName,
                  label: this.ellipsisText(path.backend.serviceName),
                  isServiceNode: true,
                  port: "Port: " + path.backend.servicePort,
                  value: path.backend.serviceName,
                  description: "Service"
                });

              this.links.push({
                source: item.host,
                target: item.host + "/" + path.backend.serviceName
              });

              if (!isEmpty(path.backend.Service)) {
                if (path.backend.Service.endpoints && path.backend.Service.endpoints.length > 0) {
                  let key =
                    "notReadyAddresses" in path.backend.Service.endpoints[0] ? "notReadyAddresses" : "addresses";

                  path.backend.Service.endpoints[0][key].forEach(pod => {
                    !includes(this.uniqueNodeIds, pod.targetRef.uid) &&
                      this.data.push({
                        id: pod.targetRef.uid,
                        label: this.ellipsisText(pod.targetRef.name),
                        value: pod.targetRef.name,
                        description: "Pod",
                        namespaceLabel: this.ellipsisText("Namespace: " + pod.targetRef.namespace),
                        namespaceValue: pod.targetRef.namespace,
                        isPodNode: true,
                        isReady: key == "notReadyAddresses" ? false : true
                      });

                    this.links.push({
                      source: item.host + "/" + path.backend.serviceName,
                      target: pod.targetRef.uid
                    });
                  });
                }
              }
            }
          });
        }
      });

      this.loading = false;
    },

    drawTopo() {
      let _this = this;
      G6.registerNode(
        "ingress-node",
        {
          draw(cfg, group) {
            let container = group.addShape("rect", {
              attrs: {
                radius: 5,
                fill: "#ffffff",
                fillOpacity: 1,
                width: 200,
                height: cfg.isPodNode || cfg.isServiceNode ? 74 : 60,
                x: 0,
                y: 0,
                shadowColor: "rgba(0, 99, 206, 0.2)",
                shadowOffsetX: 0,
                shadowOffsetY: 1,
                shadowBlur: 4
              }
            });

            group.addShape("rect", {
              attrs: {
                radius: 10,
                fill: "#314659",
                width: 120,
                height: 30,
                x: 30,
                y: -10
              }
            });

            group.addShape("image", {
              attrs: {
                width: 16,
                height: 16,
                img: _this.imageObj[cfg.description],
                x: 40,
                y: -4
              }
            });

            group.addShape("text", {
              attrs: {
                fontSize: 14,
                fill: "#fff",
                text: cfg.description,
                x: 64,
                y: 12
              }
            });

            group.addShape("text", {
              attrs: {
                x: 20,
                y: 44,
                text: cfg.label,
                fontSize: 12,
                fill: "#314659"
              }
            });

            cfg.isPodNode &&
              group.addShape("text", {
                attrs: {
                  x: 20,
                  y: 64,
                  text: cfg.namespaceLabel,
                  fontSize: 12,
                  fill: "#314659"
                }
              });

            cfg.isPodNode &&
              group.addShape("image", {
                attrs: {
                  width: 16,
                  height: 16,
                  img: cfg.isReady ? SuccessIcon : WarnIcon,
                  x: 120,
                  y: -4
                }
              });

            cfg.isServiceNode &&
              group.addShape("text", {
                attrs: {
                  x: 20,
                  y: 64,
                  text: cfg.port,
                  fontSize: 12,
                  fill: "#314659"
                }
              });

            return container;
          },

          getAnchorPoints() {
            return [[0, 0.5]];
          }
        },
        "modelRect"
      );
      const width = document.getElementById("container").scrollWidth;

      const tooltip = new G6.Tooltip({
        offsetX: 10,
        offsetY: 10,
        itemTypes: ["node"],

        getContent: e => {
          const outDiv = document.createElement("div");
          let slot = "";

          e.item.getModel().isPodNode &&
            (slot = `<div style="margin-top: 10px;">
                <span class="title">Namespace:</span>
                ${e.item.getModel().namespaceValue}
              </div>
              <div>
                <span class="title">Status:</span>
                <span style="color: ${e.item.getModel().isReady ? "#67c23a" : "#ff9f00"}">
                ${e.item.getModel().isReady ? "Ready" : "NotReady"}</span>
              </div>
            `);

          e.item.getModel().isServiceNode &&
            (slot = `
              <div style="margin-top: 10px;">
                <span class="title">Port:</span>
                ${e.item.getModel().port}
              </div>

            `);

          let template = `
            <div class="title" > ${e.item.getModel().value}</div>
            ${slot}
           `;

          outDiv.innerHTML = `
            <div style="font-size: 12px;">${template}</div>`;

          return outDiv;
        }
      });

      const contextMenu = new G6.Menu({
        offsetX: 10,
        offsetY: 10,
        itemTypes: ["node"],

        getContent: e => {
          let type = e.item.getModel().description;

          let { href } = this.$router.resolve({
            path: `/detail/${type}/${e.item.getModel().value}`,
            query: {
              vendor: this.vendor,
              region: this.region,
              cluster: this.cluster,
              namespace: this.namespace
            }
          });

          let link = includes(["Service", "Pod"], type)
            ? `<div> <a href='${href}' target='_blank'>查看资源详情</a></div>`
            : "";

          return `
            <div>
              <div class='title' style='margin-bottom: 10px;'>${e.item.getModel().value}</div>
              <div><a href='javascript:;'>复制名称</a></div>
              ${link}
            </div>
          `;
        },

        handleMenuClick: (target, item) => {
          this.$copyText(item.getModel().value).then(() => {
            this.$notify({
              title: "Success",
              message: "复制成功",
              type: "success"
            });
          });
        }
      });

      const toolbar = new G6.ToolBar({
        position: { x: 10, y: 10 }
      });

      this.graph = new G6.Graph({
        width: width,
        height: 600,
        container: "container",
        plugins: [tooltip, contextMenu, toolbar],
        fitView: true,
        autoPaint: true,
        layout: {
          type: "dagre",
          rankdir: "LR",
          align: "DL",
          nodesep: 20,
          ranksep: 30
        },

        defaultNode: {
          type: "ingress-node",
          size: [220, 60],
          logoIcon: {
            show: false
          },
          stateIcon: {
            show: false
          }
        },

        minZoom: 0.5,
        maxZoom: 1.5,

        defaultEdge: {
          type: "cubic-horizontal",

          style: {
            lineWidth: 1,
            stroke: "#7e93a9",
            strokeOpacity: 0.5
          }
        },

        modes: {
          default: ["drag-node", "drag-canvas"]
        }
      });

      this.graph.data({
        nodes: this.data,
        edges: this.links
      });

      this.graph.render();
    },

    ellipsisText(str) {
      return str.length > 20 ? str.substring(0, 20) + "..." : str;
    }
  },

  computed: {
    uniqueNodeIds() {
      return this.data.map(item => item.id);
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    }
  },

  beforeDestroy() {
    if (this.graph) this.graph.destroy();
  }
};
</script>

<style lang="scss" scoped>
#container {
  position: relative;
  overflow: hidden;
  background-image: url("../../../assets/topoBG.svg");
  background-repeat: repeat;
  touch-action: none;

  .zoom-tools {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .tools-icon {
    text-align: center;
    margin: 16px 0;
    cursor: pointer;

    & > i {
      font-weight: bolder;
    }
  }
}
</style>
