<template>
  <div>
    <div v-if="netdataValid">
      <div class="gauge-container">
        <div v-for="item in gaugeList" :key="item.id" class="gauge-item">
          <div
            :data-netdata="item.url"
            :data-host="hostUrl"
            :data-dimensions="item.dimensions"
            :data-title="item.title"
            :data-gauge-max-value="item.maxValue"
            :data-append-options="item.appendOptions"
            :data-units="item.units"
            data-chart-library="gauge"
            data-gauge-adjust="width"
            data-before="0"
            data-after="-420"
            data-points="420"
          ></div>
        </div>
      </div>

      <div v-for="item in dygraphList" :key="item.id">
        <div
          :data-netdata="item.url"
          :data-host="hostUrl"
          data-chart-library="dygraph"
          data-width="100%"
          data-height="240"
          style="padding: 20px 0;"
        ></div>
      </div>
    </div>

    <result type="empty" v-else />
  </div>
</template>

<script>
import loadJS from "load-js";
import { monitorAddress } from "api/monitor";
import Result from "@/components/Result.vue";

export default {
  components: {
    Result
  },

  props: {
    id: [String],
    node: [String],
    podValid: [String]
  },

  data() {
    return {
      hostUrl: "",

      dygraphList: [
        {
          type: "cpu_limit"
        },

        {
          type: "cpu"
        },

        {
          type: "cpu_per_core"
        },

        {
          type: "mem_usage_limit"
        },

        {
          type: "mem_usage"
        },

        {
          type: "mem"
        },

        {
          type: "writeback"
        },

        {
          type: "mem_activity"
        },

        {
          type: "pgfaults"
        }
      ],

      gaugeList: [
        {
          type: "cpu_limit",
          title: "CPU",
          maxValue: "100",
          dimensions: "used",
          units: "%"
        },
        {
          type: "mem_usage_limit",
          title: "Memory",
          dimensions: "used",
          maxValue: "100",
          appendOptions: "percentage",
          units: "%"
        },
        {
          type: "net_eth0",
          dimensions: "received",
          title: "Received eth0"
        },
        {
          type: "net_eth0",
          dimensions: "sent",
          title: "Sent eth0"
        }
      ],

      netdataDomain: "",
      netdataValid: true
    };
  },
  methods: {
    generate() {
      this.hostUrl = `${this.netdataDomain}/host/${this.node}`;

      let url = this.id;

      if (url.length > 107) {
        url = url.substr(0, 107);
      }

      this.dygraphList.forEach(item => {
        this.$set(item, "id", `${url}.${item.type}`);
        this.$set(item, "url", `${url}.${item.type}`);
      });

      this.gaugeList.forEach(item => {
        this.$set(item, "id", `${url}.${item.type}.${item.dimensions}`);
        this.$set(item, "url", `${url}.${item.type}`);
      });

      window.NETDATA.unpause();
    }
  },

  async mounted() {
    //判断Pod的健康状态
    if (this.podValid === "Running") {
      let response = await monitorAddress({ ...this.$route.query }, { type: "Netdata" });

      if (response.code === 0) {
        this.netdataDomain = response.data;
        this.netdataValid = true;

        if (!window.NETDATA) {
          loadJS([`${this.netdataDomain}/dashboard.js`])
            .then(() => {
              this.generate();
            })
            .catch(() => {
              this.netdataValid = false;
            });
        } else {
          this.generate();
        }
      } else {
        this.netdataValid = false;
      }
    } else {
      this.netdataValid = false;
    }
  },

  beforeDestroy() {
    if (window.NETDATA) window.NETDATA.pause();
  }
};
</script>

<style lang="scss" scoped>
.gauge-container {
  margin-top: 50px;
  text-align: center;
}

.gauge-item {
  height: 150px;
  width: 20%;
  display: inline-block;
}
</style>
