<template>
  <div>
    <div @click="toTaints">
      {{ $t("taints") }}
    </div>

    <el-dialog :title="$t('taints')" :visible.sync="dialogVisible" :append-to-body="true" width="1000px">
      <el-button
        type="default"
        size="small"
        style="margin-bottom: 10px; "
        plain
        icon="plus"
        @click="taints.push({ key: '', value: '', effect: 'NoSchedule' })"
      >
        + {{ $t("handle.add") }}
      </el-button>

      <div class="desc" style="margin-bottom: 10px;">{{ $t("taintsDesc") }}</div>

      <div v-for="(item, index) in taints" :key="index">
        <el-row :gutter="10" style="margin-bottom: 10px;">
          <el-col :span="8">
            <el-input v-model.trim="item.key" size="small">
              <template slot="prepend">
                Key
              </template>
            </el-input>
          </el-col>

          <el-col :span="8">
            <el-input v-model.trim="item.value" size="small">
              <template slot="prepend">
                Value
              </template>
            </el-input>
          </el-col>

          <el-col :span="6">
            <el-select size="small" v-model="item.effect" clearable style="width: 100%;">
              <el-option :label="$t('taintsNoSchedule')" value="NoSchedule"> </el-option>
              <el-option :label="$t('taintsPreferNoSchedule')" value="PreferNoSchedule"> </el-option>
              <el-option :label="$t('taintsNoExecute')" value="NoExecute"> </el-option>
            </el-select>
          </el-col>

          <el-col :span="2">
            <el-button icon="el-icon-delete" circle @click="taints.splice(index, 1)" size="small"></el-button>
          </el-col>
        </el-row>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="loading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { nodeUpdate } from "api/cluster";
import { cloneDeep } from "lodash";
export default {
  props: {
    data: {
      type: Object
    }
  },

  data() {
    return {
      dialogVisible: false,
      taints: [],
      loading: false
    };
  },

  methods: {
    toTaints() {
      this.dialogVisible = true;
      this.taints = this.data.spec.taints ? cloneDeep(this.data.spec.taints) : [];
    },

    submit() {
      this.loading = true;
      let data = cloneDeep(this.data);
      data.spec.taints = this.taints;

      nodeUpdate({ ...this.$route.query, node: this.$route.params.name }, data).then(response => {
        this.loading = false;
        if (response.code === 0) {
          this.dialogVisible = false;
          this.$emit("update");
        }
      });
    }
  }
};
</script>

<style></style>
