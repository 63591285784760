<template>
  <el-tabs>
    <el-tab-pane :label="$t('resourceRelated')">
      <el-tabs type="card">
        <el-tab-pane :label="$t('application')">
          <application-list type="Deployment" />
          <application-list type="StatefulSet" />
          <application-list type="CronJob" />
          <application-list type="DaemonSet" />
        </el-tab-pane>

        <el-tab-pane :label="$t('ConfigMap')">
          <config-map-list type="ConfigMap" />
        </el-tab-pane>

        <el-tab-pane :label="$t('Secret')">
          <config-map-list type="Secret" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>

    <el-tab-pane :label="$t('Dashboard')">
      <application-rank :namespace="namespace" :cluster="cluster" :region="region" :vendor="vendor" />
    </el-tab-pane>

    <el-tab-pane label="YAML">
      <yaml-container :text="$attrs.data.spec.namespace" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import ApplicationList from "@/pages/Detail/components/ApplicationList";
import ConfigMapList from "@/pages/Detail/components/ConfigMapList";
import ApplicationRank from "@/pages/Detail/components/ApplicationRank";

export default {
  components: {
    ApplicationRank,
    ApplicationList,
    ConfigMapList
  },

  computed: {
    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.params.name;
    }
  }
};
</script>
