<template>
  <el-row :gutter="20" v-html="sortConditions()"> </el-row>
</template>

<script>
import i18n from "@/lang";

export default {
  props: {
    conditions: Array
  },

  methods: {
    sortConditions() {
      let conditions = ["Initialized", "PodScheduled", "ContainersReady", "Ready"];

      let html = "";

      for (let condition of conditions) {
        for (let item of this.conditions) {
          if (condition === item.type) {
            html += `
            <div class="el-col el-col-6" style="padding: 10px">
              <div class="title ${item.status === "True" ? "primary" : "warning"}">
                  ${item.type}
                </div>

              <div class="name-value">
                <span class="name">${i18n.t("time")}: </span>
                <span class="value">${this.moment(item.lastTransitionTime).format("YYYY-MM-DD HH:mm:ss")}</span>
              </div>

              <div class="name-value">
                <span class="name">${i18n.t("reason")}: </span>
                <span class="value">${item.reason || "-"}</span>
              </div>

            </div>`;
          }
        }
      }

      return html;
    }
  }
};
</script>
