<template>
  <div @click="podRestart">
    {{ $t("handle.restart") }}
  </div>
</template>

<script>
import { podRestart } from "api/deployment";
export default {
  methods: {
    podRestart() {
      const { name } = this.$route.params;

      this.$confirm(this.$t("restart", [name]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          podRestart({ pod: name, ...this.$route.query }).then(response => {
            if (response.code === 0) {
              this.$notify({
                title: "Success",
                message: "Success",
                type: "success"
              });

              this.$router.go(-1);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style></style>
