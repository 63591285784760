<template>
  <div class="detail-container">
    <breadcrumb />

    <div v-loading="loading">
      <div class="detail-tab" v-if="!loading">
        <div class="detail-info-header">
          <svg-icon :icon-class="$route.params.type"></svg-icon>
          <div class="name-info">
            <div class="name">{{ $route.params.name }}</div>
            <div class="desc">{{ $route.params.type }} - {{ resourceLocation }}</div>
          </div>

          <div class="handle-buttons">
            <collect />

            <div>
              <el-dropdown trigger="click">
                <div class="handle-button">
                  <span>{{ $t("handle.moreHandle") }}</span>
                  <i class="el-icon-more-outline el-icon--right"></i>
                </div>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="['Deployment', 'StatefulSet', 'DaemonSet'].includes(type)">
                    <update
                      :vendor="vendor"
                      :region="region"
                      :cluster="cluster"
                      :application="name"
                      :namespace="namespace"
                      @update="init"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['Deployment'].includes(type)">
                    <rollback
                      :vendor="vendor"
                      :region="region"
                      :cluster="cluster"
                      :application="name"
                      :namespace="namespace"
                      @update="init"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['Deployment', 'StatefulSet'].includes(type)">
                    <scale
                      :vendor="vendor"
                      :region="region"
                      :cluster="cluster"
                      :application="name"
                      :namespace="namespace"
                      @update="init"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['Deployment'].includes(type)">
                    <auto-scale
                      :vendor="vendor"
                      :region="region"
                      :cluster="cluster"
                      :application="name"
                      :namespace="namespace"
                      @update="init"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['CronJob'].includes(type)">
                    <pause
                      :pause="info.spec.cronjob.spec.suspend"
                      :vendor="vendor"
                      :region="region"
                      :cluster="cluster"
                      :application="name"
                      :namespace="namespace"
                      @update="init"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['Deployment', 'StatefulSet', 'DaemonSet', 'CronJob'].includes(type)">
                    <hotfix :data="info" />
                  </el-dropdown-item>

                  <!-- <el-dropdown-item v-if="['Deployment', 'StatefulSet', 'DaemonSet', 'CronJob'].includes(type)">
                    <copy :data="info" />
                  </el-dropdown-item> -->

                  <el-dropdown-item v-if="['ConfigMap', 'Secret'].includes(type)">
                    <config-map-update :data="info" @update="init" />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['Namespace'].includes(type)">
                    <namespace-update :data="info" @update="init" />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['Node'].includes(type)">
                    <schedulable :unschedulable="info.spec.unschedulable ? true : false" />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['Node'].includes(type)">
                    <taints :data="info" @update="init" />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['Ingress'].includes(type)">
                    <ingress-update :data="info" @update="init" />
                  </el-dropdown-item>

                  <el-dropdown-item v-if="['Pod'].includes(type)">
                    <restart :data="info" />
                  </el-dropdown-item>

                  <el-dropdown-item
                    v-if="
                      [
                        'Deployment',
                        'StatefulSet',
                        'DaemonSet',
                        'ConfigMap',
                        'Secret',
                        'Ingress',
                        'Namespace',
                        'Service',
                        'PersistentVolume',
                        'PersistentVolumeClaims'
                      ].includes(type)
                    "
                  >
                    <delete
                      :vendor="vendor"
                      :region="region"
                      :cluster="cluster"
                      :name="name"
                      :namespace="namespace"
                      :type="type"
                      @delete="routerGo"
                    />
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>

        <application-tab :data="info" v-if="['Deployment', 'StatefulSet', 'DaemonSet', 'CronJob'].includes(type)" />
        <config-map-tab :data="info" v-if="['ConfigMap', 'Secret'].includes(type)" />
        <cluster-tab v-if="type == 'Cluster'" />
        <node-tab :data="info" v-if="type == 'Node'" />
        <pvc-tab :data="info" v-if="type == 'PersistentVolumeClaims'" />
        <pod-tab :data="info" v-if="type == 'Pod'" />
        <service-tab :data="info" v-if="type == 'Service'" />
        <namespace-tab :data="info" v-if="type == 'Namespace'" />
        <ingress-tab :data="info" v-if="type == 'Ingress'" />
        <pv-tab :data="info" v-if="type == 'PersistentVolume'"></pv-tab>
      </div>

      <div class="detail-sider" v-if="!loading">
        <div class="detail-info-header">
          <svg-icon icon-class="tag"></svg-icon>
          <div class="name-info">
            <div class="name">{{ $t("basicConf") }}</div>
          </div>
        </div>

        <div class="sider-extra-info">
          <location />

          <div
            v-if="
              [
                'Deployment',
                'StatefulSet',
                'DaemonSet',
                'CronJob',
                'Pod',
                'Node',
                'Service',
                'Ingress',
                'Namespace',
                'ConfigMap',
                'Secret',
                'PersistentVolume',
                'PersistentVolumeClaims'
              ].includes(type)
            "
          >
            <div class="resource-title">{{ $t("tag") }}</div>
            <labels :labels="info.metadata.labels" class="extra-label" edit-type="label" />

            <div class="resource-title">{{ $t("annotations") }}</div>
            <labels
              :labels="info.metadata.annotations"
              class="extra-label"
              editable
              edit-type="annotation"
              @update="init"
            />
          </div>

          <div class="resource-title">{{ $t("detail") }}</div>
          <div class="name-value" v-for="item in detailList" :key="item.name">
            <span class="name">{{ item.name }}：</span>
            <span class="value">
              <i class="el-icon-document-copy" v-clipboard:copy="item.value"></i>
              {{ item.value }}
            </span>
          </div>

          <conditions v-if="['Deployment'].includes(type) && !loading" :info="info" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationTab from "./Application/ApplicationTab";
import ConfigMapTab from "./ConfigMap/ConfigMapTab";
import ClusterTab from "./Cluster/ClusterTab";
import NodeTab from "./Node/NodeTab";
import PvcTab from "./Pvc/PvcTab";
import PvTab from "./Pv/PvTab";
import PodTab from "./Pod/PodTab";
import ServiceTab from "./Service/ServiceTab";
import NamespaceTab from "./Namespace/NamespaceTab";
import IngressTab from "./Ingress/IngressTab";

import Location from "./Location";

import Breadcrumb from "@/components/Breadcrumb";
import Collect from "@/components/Collect";

import { deploymentDetail } from "api/deployment";
import { configMapDetail } from "api/configmap";
import { secretDetail } from "api/secret";
import { nodeDetail } from "api/cluster";
import { serviceDetail } from "api/service";
import { pvcDetail, pvDetail } from "api/pv";
import { clusterDetail } from "api/cluster";
import { ingressDetail } from "api/ingress";
import { namespaceDetail } from "api/namespace";

import Update from "@/pages/Application/Update";
import Rollback from "@/pages/Application/Rollback";
import Scale from "@/pages/Application/Scale";
import AutoScale from "@/pages/Application/AutoScale";
import Delete from "@/pages/Delete/index";
import Pause from "@/pages/Application/Pause";
import Hotfix from "@/pages/Application/Hotfix";
// import Copy from "@/pages/Application/Copy";

import ConfigMapUpdate from "@/pages/ConfigMap/Update";
import NamespaceUpdate from "@/pages/Namespace/Update";

import Schedulable from "@/pages/Node/Schedulable";
import Taints from "@/pages/Node/Taints";
import IngressUpdate from "@/pages/Ingress/Update";
import Restart from "@/pages/Pod/Restart";

import Conditions from "./Conditions";

import { mapInfo } from "utils/info.js";

export default {
  components: {
    ApplicationTab,
    ConfigMapTab,
    NodeTab,
    IngressTab,
    ClusterTab,
    PodTab,
    ServiceTab,
    PvcTab,
    NamespaceTab,
    PvTab,

    Breadcrumb,
    Collect,
    Location,

    Update,
    Rollback,
    Scale,
    Delete,
    Hotfix,
    Pause,
    AutoScale,
    // Copy,

    ConfigMapUpdate,
    NamespaceUpdate,
    Schedulable,
    Taints,
    IngressUpdate,
    Restart,
    Conditions
  },

  data() {
    return {
      loading: true,
      info: {},
      detailList: []
    };
  },

  methods: {
    init() {
      this.loading = true;

      let action = null;
      let data = {};

      switch (this.type) {
        case "ConfigMap":
          action = configMapDetail;
          data = {
            configmap: this.$route.params.name,
            ...this.$route.query
          };

          break;

        case "Secret":
          action = secretDetail;
          data = {
            secret: this.$route.params.name,
            ...this.$route.query
          };

          break;

        case "Cluster":
          action = clusterDetail;
          data = {
            cluster: this.$route.params.name,
            ...this.$route.query
          };

          break;

        case "Node":
          action = nodeDetail;
          data = {
            node: this.$route.params.name,
            ...this.$route.query
          };

          break;

        case "Service":
          action = serviceDetail;
          data = {
            service: this.$route.params.name,
            ...this.$route.query
          };

          break;

        case "PersistentVolumeClaims":
          action = pvcDetail;
          data = {
            persistentvolumeclaim: this.$route.params.name,
            ...this.$route.query
          };
          break;

        case "PersistentVolume":
          action = pvDetail;
          data = {
            persistentvolume: this.$route.params.name,
            ...this.$route.query
          };
          break;

        case "Ingress":
          action = ingressDetail;
          data = {
            ingress: this.$route.params.name,
            ...this.$route.query
          };
          break;

        case "Namespace":
          action = namespaceDetail;
          data = {
            namespace: this.$route.params.name,
            ...this.$route.query
          };
          break;

        case "Deployment":
        case "StatefulSet":
        case "DaemonSet":
        case "CronJob":
        case "Pod":
          action = deploymentDetail;
          data = {
            application: this.$route.params.name,
            ...this.$route.query
          };

          break;
      }

      action(data, { type: this.$route.params.type }).then(response => {
        if (response.code === 0) {
          this.info = response.data;
          this.detailList = mapInfo(this.type, this.info);
          this.loading = false;
        } else {
          this.$router.replace({ path: "/notFound" });
        }
      });
    },

    routerGo() {
      this.$router.push({ path: `/list/${this.$route.params.type}` });
    }
  },

  mounted() {
    this.init();
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    name() {
      return this.$route.params.name;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    resourceLocation() {
      let str = "";

      Object.keys(this.$route.query).forEach(item => {
        str += this.$route.query[item] + "/";
      });

      return str.substring(0, str.length - 1);
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.detail-container {
  position: relative;
}

.detail-tab {
  margin-right: 380px;

  & > .el-tabs {
    padding: 2 * $grid-height;
    border-radius: 0 0 $radius-width $radius-width;
    border: none;
    box-shadow: $common-shadow;

    .el-tabs__nav-wrap::after {
      display: none;
    }
  }
}

.detail-info-header {
  @include flex(flex-start);
  background-color: rgba($color: #001529, $alpha: 0.9);
  padding: 2 * $grid-height;
  color: #fff;
  border-radius: $radius-width $radius-width 0 0;

  .name-info {
    .name {
      @include title(14px);
      @include text-overflow();
      margin-bottom: $grid-height;
      width: 100%;
      color: #fff;
    }

    .desc {
      color: $color-sub;
      font-size: $normal-font-size;
    }
    flex: 1;
    overflow: hidden;
  }
}

.detail-sider {
  transition: 0.2s all ease;
  position: absolute;
  right: 0;
  top: 30px;
  width: 360px;
  box-sizing: border-box;
  border-radius: $radius-width;
  box-shadow: $common-shadow;

  .sider-extra-info {
    padding: 2 * $grid-height;
    color: $color-main;

    .extra-label {
      margin-bottom: $grid-height;
    }
  }
}

.detail-container.openSidebar {
  .detail-sider {
    display: block;
  }

  .detail-tab {
    margin-right: 380px;
  }
}

.detail-container.hideSidebar {
  .detail-sider {
    display: none;
  }

  .detail-tab {
    margin-right: 60px;
  }
}
</style>
