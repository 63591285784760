<template>
  <el-tabs>
    <el-tab-pane :label="$t('resourceRelated')">
      <el-tabs type="card">
        <el-tab-pane :label="$t('PersistentVolumeClaims')">
          <pv-pvc-list></pv-pvc-list>
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>

    <el-tab-pane label="YAML">
      <yaml-container :text="$attrs.data" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import PvPvcList from "@/pages/Detail/components/PvPvcList";
export default {
  components: {
    PvPvcList
  }
};
</script>
