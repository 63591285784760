<template>
  <el-tabs>
    <el-tab-pane :label="$t('resourceRelated')">
      <el-tabs type="card">
        <el-tab-pane :label="$t('pod')">
          <pod type="pvc" :vendor="vendor" :region="region" :cluster="cluster" :namespace="namespace" :name="pvc" />
        </el-tab-pane>
      </el-tabs>
    </el-tab-pane>

    <el-tab-pane label="YAML">
      <yaml-container :text="$attrs.data" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import Pod from "@/pages/Detail/components/Pod";
export default {
  components: {
    Pod
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    pvc() {
      return this.$route.params.name;
    }
  }
};
</script>
